import { Grid} from "@mui/material";
import React, { useRef, useState } from "react";
import { BuoyTooltip } from "../map/RLayaers/BuoyTooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TimelineIcon from '@mui/icons-material/Timeline';
import { DataChart } from "../graph/data-chart";
import { flushSync } from "react-dom";

/**
 * DataVisualizationChildItem Component
 * @constructor
 * This component renders a sensor's metric with a button to display the metric's graph.
 * @param {object} item - sensor's data.
 * @param {object} child - sensor's metric data.
 * @param {object} graphData - data for the graph.
 * @param {boolean} isGraphError - flag to indicate if there is an error with the graph.
 * @param {boolean} isGraphLoading - flag to indicate if the graph is loading.
 */
export const DataVisualisationChildItem = ({ item, child, graphData, isGraphError, isGraphLoading }) => {

  /** Reference to the dialog graph element. */
  const dialogRef = useRef(null);

  const [showDialog, setShowDialog] = useState(false);

  /** show the graph in a dialog when the graph button is clicked. */
  const showGraph = () => {
    if(isGraphError || isGraphLoading)
      return;
    flushSync(()=>{
      setShowDialog(true)
    })
    dialogRef.current.showModal();
  }

  /** close the dialog when the dialog is clicked outside of the graph. */
  const onDialogClicked = (e) => {
    if (e.target === dialogRef.current) {
      dialogRef.current.close();
    }
  }

  return (
    <>
      {/* Render the graph. */}
      {showDialog && <dialog ref={dialogRef} onClick={onDialogClicked}>
        <DataChart combinedMesureName={item.name} data={graphData} selectedGraph={child.key} 
          units={child?.fieldMeta?.units} symbol={child?.fieldMeta?.symbol}
        />
      </dialog>}
      {/* Render a metric with a button to display the metric's graph. */}
      <React.Fragment key={item.name + child.key}>
        <Grid item md={12} className={`popupItem ${child.key}`}>
          <Grid item md={7} className="popupName">
            <div style={{ display: "flex", alignItems: "center" }}>
              {!child.fieldMeta.hideSymbol && <div className={`sign`}>{child.fieldMeta.symbol || child.key} - </div>}
              <div className="title">{child.fieldMeta.title}</div>
              {child.fieldMeta.tooltip && <BuoyTooltip title={child.tooltip}>
                <HelpOutlineIcon
                  style={{
                    paddingLeft: "2px",
                    fontSize: "11px",
                    paddingBottom: "2px",
                  }}
                />
              </BuoyTooltip>}
            </div>
          </Grid>
          <Grid item md={4} className="popupValue" alignContent="end">
            {child.formatted}
          </Grid>
          {!child.fieldMeta.hideGraph && <Grid item md={1}>
              <div className= {`tooltip-graph-icon ${isGraphLoading ? 'loading' : ''} ${isGraphError ? 'error' : ''}`}
                onClick={()=>showGraph()}
              ><TimelineIcon/></div>
          </Grid>}
        </Grid>
      </React.Fragment>
    </>
  );
};
