import { RLayerVector } from "rlayers";
import { Point } from "ol/geom";
import { fromLonLat } from "ol/proj";
import { buoysConfig, markerTypes } from "../../../utils/buoyConnect";
import { filterValues } from "../../utils/inputReducer";
import { adjustCoordinates } from "../../utils/icons-intersections";
import { BuoyItem } from "./BuoyItem";

/** Function to update the markers based on configuration data. */
const updateMarkers = () => {
  let latLongs = [];
  for (let conf of buoysConfig) {
    const wsUrl = conf.ws;
    // let ws = zefirSockets[wsUrl]
    for (let marker of conf.points) {
      if (marker.lat) {
        latLongs.push({
          ...marker,
          _lon: marker.lon,
          _lat: marker.lat,
          id: marker.id,
          icon: markerTypes[marker.type].icon,
          geometry: new Point(fromLonLat([marker.lon, marker.lat])),
          wsUrl,
        });
      }
    }
  }
  return latLongs;
};

const _buoys = updateMarkers();

/**
 * BuoyLayer Component
 * @constructor
 * This component renders a layer on the map which displays buoys.
 * @param {number} zIndex - zIndex of the layer.
 * @param {object} filterList - filterList is a set of strings which determine which data to display.
 * @param {number} radiusInMeters - radiusInMeters is the radius of the circle in which the buoys are displayed.
 */
export const BuoyLayer = ({ zIndex = 15, filterList, radiusInMeters }) => {
  /** Adjust the coordinates of the buoy markers based on a radius. */
  const buoys = adjustCoordinates({ buoys: _buoys, radius: radiusInMeters });

  /** Create a set of the filterList to be used in filtering the markers. */
  const filterArr = Array.from(filterList).map((item) => filterValues[item]);

  // const markersFilter = new Set(
  //   Array.from(filterList, (item) => {
  //     const toRet = filterValues[item]
  //     return toRet;
  //   }).flat()
  // );

  /** Create a set of the filterList to be used in filtering the markers. */
  const markersFilter = new Set(filterArr.flat());

  return (
    buoys && (
      <RLayerVector zIndex={zIndex}>
        {/*Create a style for rendering the features*/}
        {buoys
          .filter((item) => markersFilter.has(item.type))
          .map((item, index) => {
            return <BuoyItem item={item} key={item.id}/>;
          })}
      </RLayerVector>
    )
  );
};
