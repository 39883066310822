import { isobandColors, isobandLegendData } from './isobandLegendData'

/** IsobandLegend component
 * @constructor
 * This component renders a legend for isobands based on the layerToShow.
 * @param {string} layerToShow - layer to show
 */
export const IsobandLegend = ({ layerToShow, isobandData }) => {

  /** Get the columns for the legend */
  const columns = isobandLegendData[layerToShow] || isobandLegendData.default

  // if(!isobandData || isobandData?.length === 0) return null

  return <div className="legendContainer isoband">
    {
      columns.map(({ min, max, unit, color }, index, arr) => {
        return <div className="legendChip " style={{ backgroundColor: color }} key={max}>
          <div>{index === 0 ? min : arr?.[index - 1]?.max}</div>
          {unit && <div className='unit'>{unit}</div>}
        </div>
      })
    }
  </div>

  return <div className="legendContainer">
    {
      !isobandData || isobandData?.length === 0 ?
        null
      :
      isobandData.map(({ min, max, unit }, index, arr) => {
        console.log({ min, max, unit })
        const color = isobandColors[index]
        return <div className="legendChip " style={{ backgroundColor: color }} key={max}>
          <div>{index === 0 ? min : arr?.[index - 1]?.max}</div>
          {unit && <div className='unit'>{unit}</div>}
        </div>
      })

    }
  </div>
}