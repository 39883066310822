export const fieldLayers = {
  currents_bottom: "currents_bottom",
  currents_surface: "currents_surface",
  level: "level",
  coastal_flood: "coastal_flood",
  wladyslawowo_waves: "wladyslawowo_waves",
  jastarnia_waves: "jastarnia_waves",
  height: "height",
  period: "period",
}

/**
 * @param {Object} configuration
 * This function returns object with information which field layer should be shown
 * and which arrow layer should be shown
 */
export const configToFieldLayer = (configuration) => {

  const showFieldLayer =
    configuration.showWaveHeight ||
    configuration.showWavePeriod ||
    configuration.showBottomCurrents ||
    configuration.showSurfaceCurrents ||
    configuration.showCoastalFlood ||
    configuration.showLevel ||
    configuration.showWladyslawowoWaves ||
    configuration.showJastarniaWaves
  const showArrowsLayer = configuration.showWaveHeight || configuration.showWavePeriod;
  let fieldLayerToShow = null;
  if (configuration.showBottomCurrents) {
    fieldLayerToShow = fieldLayers.currents_bottom;
  } else if (configuration.showSurfaceCurrents) {
    fieldLayerToShow = fieldLayers.currents_surface;
  } else if (configuration.showLevel) {
    fieldLayerToShow = fieldLayers.level;
  } else if (configuration.showCoastalFlood) {
    fieldLayerToShow = fieldLayers.coastal_flood;
  } else if (configuration.showWladyslawowoWaves){
    fieldLayerToShow = fieldLayers.wladyslawowo_waves
  } else if (configuration.showJastarniaWaves){
    fieldLayerToShow = fieldLayers.jastarnia_waves
  }
  let arrowLayerToShow = null;
  if (configuration.showWaveHeight) {
    arrowLayerToShow = "height";
  } else if (configuration.showWavePeriod) {
    arrowLayerToShow = "period";
  } 
  return { showFieldLayer, fieldLayerToShow, showArrowsLayer, arrowLayerToShow };
};