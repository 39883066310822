import { Fill, RegularShape, Stroke, Style, Text } from "ol/style";

const arrowColor = "#00436A";

/** shaft is a style for the shaft of the arrow. 
 * shaft represents the main body of an arrow
*/
const shaft = new RegularShape({
  points: 2,
  radius: 5,
  stroke: new Stroke({
    width: 2,
    color: arrowColor,
  }),
  rotateWithView: true,
});

/** head is a style for the head of the arrow. */
const head = new RegularShape({
  points: 3,
  radius: 5,
  fill: new Fill({
    color: arrowColor,
  }),
  rotateWithView: true,
});

/** styles is an array of styles for the arrow. */
const styles = [new Style({ image: shaft }), new Style({ image: head })];

/** TOTAL_POINTS is the total number of points in the line. */
const TOTAL_POINTS = 5;

/** styleForArrow is a function that returns a style for a feature.
 * @param {string} magnitudeField - magnitudeField is either 'u' or 'q' to determine which data to display.
 * @returns {function} style function.
 * @param {object} feature - feature object.
 * @param {number} resolution - resolution of the map.
 * @returns {array} array of styles.
 * */
export const styleForArrow =
  ({ magnitudeField }) =>
  (feature, resolution) => {
    const azWek = feature.get("az_wek");
    const index = feature.get("index") / 40;

    let modulo;
    /** adjust the appearance of the arrow based on zoom level */
    modulo = Math.round(140 / resolution);
    if (modulo < 2) {
      if (index != 1) return null;
    } else if ((index + 1) % TOTAL_POINTS >= modulo) {
      return null;
    }
    
    const magnitude = feature.get(magnitudeField)

    /** rotate arrow away from wind origin */ 
    const angle = (azWek * Math.PI) / 180;

    const scale = magnitude/ 4;
    // const scale = magnitude * (Math.abs(magnitude) > 10 ? 10/magnitude : 1);

    shaft.setScale([1, scale]);
    shaft.setRotation(angle);
    // if(Math.abs(magnitude) > 10){
    //   shaft.setStroke(new Stroke({
    //     width: 2,
    //     color: 'red',
    //   }))
    // }else{
    //   shaft.setStroke(new Stroke({
    //     width: 2,
    //     color: arrowColor,
    //   }))
    // }

    /** Conditionally adjust the displacement of the 'head' of the arrow based on the sign of magnitude.
     * 'head' is being moved along the shaft of the arrow.
     */
    if(magnitude > 0){
      head.setDisplacement([0, head.getRadius() / 2 + shaft.getRadius() * scale]);
    }else{
      head.setDisplacement([0, head.getRadius() / 2 - shaft.getRadius() * scale]);
    }
    head.setRotation(angle);

    let textStyle = new Style({
      overflow: true,
      text: new Text({
        // text: magnitude > 0 ? magnitude + "" : "",
        text: magnitude,
        font: "bold 12px Arial",
        offsetY: 10,
        offsetX: 0,
        fill: new Fill({ color: "black" }),
        stroke: new Stroke({ color: "white", width: 2 }),
      }),
    });

    return [...styles, textStyle];
  };

/** getProfileGeometryById returns the geometry object of a profile by its id. 
 * geometry object contains the coordinates of the profile(start and end points of the line).
 * @param {string} id - id of the profile.
 * @param {object} profiles - profiles object.
 * @returns {object} profile geometry object.
*/
export function getProfileGeometryById({id, profiles}){
  const profile = profiles.features.find((p) => p.properties.id === id);
  if (!profile) {
    console.log(`Profile with id ${id} not found`);
    return null;
  }
  return profile.geometry;
}

/** getPerpendicularProfileAngle returns the angle to set the arrow perpendicular to the profile.
 * @param {array} startPoint - start point of the profile.
 * @param {array} endPoint - end point of the profile.
 * @returns {number} angle to set the arrow perpendicular to the profile.
 */
export function getPerpendicularProfileAngle(startPoint, endPoint){
  const dx = endPoint[0] - startPoint[0];
  const dy = endPoint[1] - startPoint[1];
  const angle = 180 - (Math.atan2(dy, dx) * 180  / Math.PI) ;
  return angle;
}

/** getPointOnLine returns the coordinates of a point on a line given the start and end points of the line 
 * and the percentage of the line to travel from the start point to the end point.
 * @param {array} start - start point of the line.
 * @param {array} end - end point of the line.
 * @param {number} percent - percentage of the line to travel from the start point to the end point.
 * @returns {array} coordinates of the point on the line.
 */
export const getPointOnLine = (start, end, percent) => {
  const dx = end[0] - start[0];
  const dy = end[1] - start[1];
  const x = start[0] + dx * percent;
  const y = start[1] + dy * percent;
  return [x, y];
};
