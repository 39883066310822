import { configuration } from "../config"

/** This function fetches coastal currents data from the server.
 * @param {string} forecast - forecast date.
 * @returns {object} - coastal currents data.
 */
export const fetchCoastalCurrents = (forecast) => {
  return fetch(`${configuration.serverUri}/coastal_currents/${forecast}`)
    .then(async res => {
      try {
        const rawResponseText = await res.text();
        const data = rawResponseText.replace(/Infinity/g, 0).replace(/NaN/g, 0);
        const _data = JSON.parse(data);
        return _data;
      } catch (e) {
        console.log('fetchCoastalCurrents err', e);
      }
    })
}
