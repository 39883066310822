import { RLayerVector } from "rlayers";
import GeoJSON from "ol/format/GeoJSON";
import { fetchWaves, prepareWaves } from "../../../utils/prepareWaves";
import { useContext, useEffect, useRef, useState } from "react";
import { Vector as SourceVector } from "ol/source";
import { AppContext } from "../../../App";
import { useQuery } from "react-query";
import { configToFieldLayer } from "../utils/olpMap";

class FeaturesRefreshLayer extends RLayerVector {
  refresh(prevProps) {
    if (prevProps?.features !== this.props.features) {
      this.source = new SourceVector({
        features: this.props.features,
        url: this.props.url,
        format: this.props.format,
        loader: this.props.loader,
      });
      super.refresh(prevProps);
    }
  }
}

/**
 * ArrowsLayer Component
 * @constructor
 * This component renders a layer on the map which displays arrows for waves.
 * @param {string} layerToShow - layerToShow is either 'height' or 'period' to determine which data to display.
 * @param {number} zIndex - zIndex of the layer.
 * @param {object} configuration - configuration object.
 */
export const ArrowsLayer = ({ zIndex = 10, layerToShow, configuration }) => {
  const [waveFeatures, setWaveFeatures] = useState(false);

  const {
    showWaveHeight,
    showWavePeriod,
    showSurfaceCurrents,
    showBottomCurrents,
  } = configuration;

  const { showArrowsLayer } = configToFieldLayer(configuration);

  const showArrows = 
    (showArrowsLayer && showBottomCurrents && layerToShow==="currents_bottom") ||
    (showArrowsLayer && showSurfaceCurrents && layerToShow==="currents_surface") ||
    (showArrowsLayer && (showWaveHeight || showWavePeriod) && ["height", "period"].includes(layerToShow))

  const {currentForecast, setCurrentForecast, forecasts, nextForecast, pointData, setPointData} = useContext(AppContext);

  /** Effect hook to fetch wave data when currentForecast changes. */
  const {data} = useQuery({
      queryKey: ['forecastData', currentForecast],
      queryFn: () => fetchWaves(currentForecast),
      enabled: !!currentForecast,
      retry: false
  });

  /** Effect hook to fetch wave data when nextForecast changes.
   * This is used to fetch data to cache it for the next forecast.
   */
  const {data: nextForecastData} = useQuery({
      queryKey: ['forecastData', nextForecast],
      queryFn: () => fetchWaves(nextForecast),
      enabled: !!nextForecast,
      retry: false
  });

  /** This hook is used to create features from the data fetched by the useQuery hooks above.
   * This hook is called when data, currentForecast or layerToShow changes.
   * It creates features from the wave data and sets them to the waveFeatures state to be rendered on the map.
   */
  useEffect(() => {
    if(currentForecast && layerToShow && data){
      const points = data.points
      const wf = prepareWaves({layerToShow, points})
      setWaveFeatures(wf)
    }
  }, [currentForecast, layerToShow, data]);

  const isFirstLoad = useRef(true);

  /** This hook is used to find the closest point to the pointData state.
   * This hook is called when pointData or data changes.
   * It finds the closest point to the pointData state and sets it to the pointData state.
   */
  useEffect(() => {
    /** do nothing if it is the first load */
    if(data?.points && isFirstLoad.current){
      isFirstLoad.current = false
    } else
    /** find the closest point */ 
    if(pointData?.geolon && pointData?.geolat && data?.points){
      let closestPoint = data.points[0]
      let closestDistance = Math.sqrt(Math.pow(closestPoint.x - pointData.geolon, 2) + Math.pow(closestPoint.y - pointData.geolat, 2))
      data.points.forEach((point) => {
        const distance = Math.sqrt(Math.pow(point.x - pointData.geolon, 2) + Math.pow(point.y - pointData.geolat, 2))
        if(distance < closestDistance){
          closestPoint = point
          closestDistance = distance
        }
      })
      setPointData({...pointData, ...closestPoint})
    }
  }, [pointData?.geolon, pointData?.geolat, data?.points]);

  const layerRef = useRef(null);

  /** This hook is used to update the features of the layer when waveFeatures changes. */
  useEffect(() => {
    if (waveFeatures && layerRef.current) {
      layerRef.current.source.clear();
      layerRef.current.source.addFeatures(waveFeatures);
    }
  }, [waveFeatures]);

  /** do not render the layer if showArrows is false */
  useEffect(() => {
    if(!showArrows){
      setWaveFeatures([])
    }
  }, [showArrows]);

  if (!waveFeatures || !showArrowsLayer || !showArrows || !layerToShow
    || !["height", "period"].includes(layerToShow)
  ) {
    return null;
  }

  if(!showArrowsLayer){
    return (
      <div style={{position: "absolute", width: 0, height: 0}}></div>
    )
  }

  return (
    <RLayerVector
      zIndex={zIndex}
      features={waveFeatures}
      format={GeoJSON}
      // style={styleForArrow}
      declutter={true}
      ref={layerRef}
    >
      {/* Create a style for rendering the features */}
      {/* <RStyle.RStyle> */}
      {/*  /!* Consisting of a single icon, that is slightly offset*/}
      {/*  so that its center falls over the center of the feature *!/*/}
      {/* <RStyle.RIcon src={ mapConfig.markerImage32} anchor={[0.5, 0.8]} /> */}
      {/* </RStyle.RStyle> */}
    </RLayerVector>
  );
};
