import { RPopup } from 'rlayers'
import React from 'react'

/**
 * RPopupWithOffset Class
 * @constructor
 * This component extends the RPopup component from the 'rlayers' library to provide additional functionality.
 * Specifically, it allows the popup to have an offset position and close button.
 */
export default class RPopupWithOffset extends RPopup {
  /**
   * setPosition Method
   * Calculates and sets the position of the popup considering the defined offsets in both x and y axes.
   */
  setPosition () {
    /** Initialize offset values for x and y axes. If offset properties are defined, use them; otherwise, default to (0, 32). */
    let x = (this.offset && this.offset.x) || 0
    let y = (this.offset && this.offset.y) || 32
    let location
    /** If the popup is visible, calculate the new location considering the offsets; otherwise, set the location to undefined. */
    if (this.visible) {
      location = this.context.location
      location[0] = location[0] + x
      location[1] = location[1] + y
    } else {
      location = undefined
    }
    // console.log('offset', this.visible, location, this.props.offset, x, y)
    /** Set the position of the popup to the new location. */
    this.ol.setPosition(location)
  }

  /**
   * render Method
   * Responsible for rendering the popup, its close button, and its content.
   * Also ensures the popup is set to the correct position when rendered.
   */
  render () {
    /** Set the popup position each time it renders. */
    this.setPosition()
    // console.log('overlay render', this.visible)
    return <div className={this.props.className} ref={this.containerRef}>
      <a href="#" id="popup-closer" className="ol-popup-closer"
         onClick={() => {
            console.log('RPopupWithOffset close')
            this.hide()
         }}></a>
      <div id="popup-content">
        {this.props.children}
      </div>
    </div>
  }
}


