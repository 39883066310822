import { ReactComponent as waveHeightIcon } from "../../assets/layersWave.svg";
import { ReactComponent as waveDirectionIcon } from "../../assets/layersWaveDirection.svg";
import { ReactComponent as deviceIcon } from "../../assets/layersDevice.svg";
import { ReactComponent as markerEdge } from "../../assets/markerEdge.svg";
import { ReactComponent as markerAdcp } from "../../assets/markerADCP.svg";
import { ReactComponent as markerMeteo } from "../../assets/markerMeteo.svg";
import { ReactComponent as markerBuoy } from "../../assets/markerBuoy.svg";
import { ReactComponent as markerCamera } from "../../assets/markerCamera.svg";


export const menuBtnNames = {
  SHOW_TILES: "showTiles",
  SHOW_BALTIC: "showBaltic",
  SHOW_MARKER: "showMarker",
  WAVES_VARIANT: "wavesVariant",
  SHOW_WAVES: "showWaves",
  SHOW_WAVE_HEIGHT: "showWaveHeight",
  SHOW_WAVE_PERIOD: "showWavePeriod",
  SHOW_COASTAL_FLOOD: "showCoastalFlood",
  SHOW_PROFILES: "showProfiles",
  SHOW_PORT: "showPort",
  SHOW_LEVEL: "showLevel",
  SHOW_CURRENTS: "showCurrents",
  SHOW_SEDIMENT: "showSediment",
  SHOW_COASTAL_EROSION: "showCoastalErosion",
  SHOW_PREDICTIONS: "showPredictions",
  SHOW_WLADYSLAWOWO_WAVES: "showWladyslawowoWaves",
  SHOW_JASTARNIA_WAVES: "showJastarniaWaves",
  MARKER_HIGHLIGHT: "markerHighlight",
  SHOW_SURFACE_CURRENTS: "showSurfaceCurrents",
  SHOW_BOTTOM_CURRENTS: "showBottomCurrents",
  SHOW_COAST_CURRENTS: "showCoastCurrents",
  SHOW_COAST_SEDIMENT: "showCoastSediment",
  SHOW_COASTAL_EROSION: "showCoastalErosion",
  SHOW_PORT_WAVES: "showPortWaves",
}

export const measureBtnNames = {
  WAVES: "waves",
  CURRENTS: "currents",
  EDGE: "edge",
  METEO: "meteo",
  CAMERA: "camera",
}

export const menuButtons = [
  {
    label: "Prognozy",
    name: "showPredictions",
    initial: true,
    icon: deviceIcon,
    children: [
      {
        label: "Wysokość fali i kierunek",
        name: menuBtnNames.SHOW_WAVE_HEIGHT,
        icon: waveDirectionIcon,
      },
      {
        label: "Okres fali i kierunek",
        name: menuBtnNames.SHOW_WAVE_PERIOD,
        icon: waveHeightIcon,
      },
      {
        label: "Prądy powierzchniowe",
        name: menuBtnNames.SHOW_SURFACE_CURRENTS,
        icon: waveHeightIcon,
      },
      {
        label: "Prądy przydenne",
        name: menuBtnNames.SHOW_BOTTOM_CURRENTS,
        icon: waveHeightIcon,
      },
      { 
        label: "Poziom wody", 
        name: menuBtnNames.SHOW_LEVEL,
        icon: waveHeightIcon 
      },
      {
        label: "Powódź odmorska",
        name: menuBtnNames.SHOW_COASTAL_FLOOD,
        icon: waveHeightIcon,
      },
      {
        label: "Prądy strefy brzegowej",
        name: menuBtnNames.SHOW_COAST_CURRENTS,
        icon: waveHeightIcon,
      },
      {
        label: "Transport osadu",
        name: menuBtnNames.SHOW_COAST_SEDIMENT,
        icon: waveHeightIcon,
      },
      {
        label: "Erozja brzegu",
        name: menuBtnNames.SHOW_COASTAL_EROSION,
        icon: waveHeightIcon,
      },
      {
        label: "Falowanie w portach",
        name: menuBtnNames.SHOW_PORT_WAVES,
        initial: false,
        icon: deviceIcon,
        children: [
          {
            label: "Wladysławowo",
            name: menuBtnNames.SHOW_WLADYSLAWOWO_WAVES,
            icon: waveDirectionIcon,
            center: [18.416, 54.795], //54.79086 18.4009
            zoom: 15,
          },
          {
            label: "Jastarnia",
            name: menuBtnNames.SHOW_JASTARNIA_WAVES,
            icon: waveDirectionIcon,
            center: [18.673, 54.694], 
            zoom: 15,
          },
        ]
      },
    ]
  },
  // {
  //   type: 'separator'
  // },
  {
    label: "Pomiary",
    name: menuBtnNames.SHOW_MARKER,
    initial: true,
    icon: deviceIcon,
    children: [
      {
        label: "Falowanie",
        name: menuBtnNames.MARKER_HIGHLIGHT,
        set: measureBtnNames.WAVES,
        icon: markerBuoy,
      },
      {
        label: "Prądy",
        name: menuBtnNames.MARKER_HIGHLIGHT,
        set: measureBtnNames.CURRENTS,
        icon: markerAdcp,
      },
      {
        label: "Poziom Wody",
        name: menuBtnNames.MARKER_HIGHLIGHT,
        set: measureBtnNames.EDGE,
        icon: markerEdge,
      },
      {
        label: "Meteo",
        name: menuBtnNames.MARKER_HIGHLIGHT,
        set: measureBtnNames.METEO,
        icon: markerMeteo,
      },
      {
        label: "Kamery",
        name: menuBtnNames.MARKER_HIGHLIGHT,
        set: measureBtnNames.CAMERA,
        icon: markerCamera,
      },
    ],
  },
  // {
  //   type: 'separator'
  // },
  {
    label: "Inne",
    icon: waveHeightIcon,
    children: [
      { 
        label: "Mapa", 
        name: menuBtnNames.SHOW_TILES,
        icon: waveHeightIcon 
      },
      { 
        label: "Bałtyk", 
        name: menuBtnNames.SHOW_BALTIC,
        icon: waveDirectionIcon 
      },
    ],
  },
];


