/** 
 * Returns an array of colors between the start and end colors.
 * @param {string} startColor - The start color.
 * @param {string} endColor - The end color.
 * @param {number} n - The number of colors to generate.
 * @returns {array} - An array of colors between the start and end colors.
 */
export function getColorsBetween(startColor, endColor, n) {
  /** Convert start and end colors to RGB values */ 
  var startRGB = hexToRGB(startColor);
  var endRGB = hexToRGB(endColor);
  
  /** Calculate the step size for each color component */ 
  var stepSize = {
    r: (endRGB.r - startRGB.r) / (n - 1),
    g: (endRGB.g - startRGB.g) / (n - 1),
    b: (endRGB.b - startRGB.b) / (n - 1)
  };
  
  /** Create an array of n colors */
  var colors = [];
  for (var i = 0; i < n; i++) {
    /** Calculate the RGB values for the current color */ 
    var r = Math.round(startRGB.r + (stepSize.r * i));
    var g = Math.round(startRGB.g + (stepSize.g * i));
    var b = Math.round(startRGB.b + (stepSize.b * i));
    
    /** Add the color to the array */ 
    colors.push(rgbToHex(r, g, b));
  }
  
  return colors;
}

/** Helper functions to convert between hex and RGB colors */ 
function hexToRGB(hex) {
  var r = parseInt(hex.substring(1, 3), 16);
  var g = parseInt(hex.substring(3, 5), 16);
  var b = parseInt(hex.substring(5, 7), 16);
  return { r: r, g: g, b: b };
}

function rgbToHex(r, g, b) {
  var hex = [
    r.toString(16).padStart(2, '0'),
    g.toString(16).padStart(2, '0'),
    b.toString(16).padStart(2, '0')
  ];
  return '#' + hex.join('');
}