import { format } from 'date-fns';
import React from 'react'
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { isobandColors } from '../utils/isobandLegendData';
import { getWindAngle, getWindSpeed, getWindType, getWindView } from '../../utils/wind';

/**
 * ADCPVChart Component
 * @constructor
 * This component renders a graph the 'v' metric and 'adcp' combined sensor name
 * @param {array} data array containing data for the graph.
 * Each element of the array is an object containing the data for each graph point.
 * Keys of the object are the [combinedSensorName] and the timestamp.
 * The value of the [combinedSensorName] key is an object with available metrics.
 */
const ADCPVChart = ({data}) => {

  /** Array of colors for the graph. */
	const colors = ['#19e4ff', '#003cff', '#5C4346', '#BF125D']

  /** Function to format the date. */
	const formatDate = (timestamp) => {
    return format(new Date(timestamp), 'yyyy-MM-dd HH:mm');
  };

  /** Convert the data to the format required by the graph. */
	const _data = []
	data.forEach((item, index) => {
    const value = []
		const angle = []
		const depth = []
    /** Get the wind speed and angle for each depth. */
		item.adcp.v?.forEach((v, i) => {
      /** If the metric index is not 0, 7, 14, 22, skip it. */
      try {
        if (![0, 7, 14, 22].includes(i))
          return
        value.push(getWindSpeed({ we: v[0], ws: v[1] }))
        angle.push(getWindAngle({ we: v[0], ws: v[1] }))
        depth.push(25 * (i + 1))
      }catch (e){
        console.warn("can't get adcp angle", e)
      }
		})
    /** Add the data to the _data array. */
		_data.push({
			timestamp: item.timestamp,
			value,
			angle,
			depth
		})
	})

  /** If the data is empty, display a message. */
  if(!_data.length) return <div className='no-data'>No data</div>

  return (
    <div>
      <LineChart width={800} height={300} data={_data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="timestamp" 
          tickFormatter={formatDate}
        />
        <YAxis 
          label={{ value: "mm/s", position: 'insideTop', dy: 10, dx: -5 }}
        />
        <Tooltip 
          labelFormatter={(value) => {
            return formatDate(value);
          }}
          formatter={(value, name, item, index, payload) => {
						const angle = item.payload.angle[index]
            return value?.toFixed(2) + 'mm/s, ' + angle?.toFixed(2) + '° ' + getWindType(angle);
          }}  
        />

        {_data[0].value.map((value, index) => {
					return <Line connectNulls type="monotone" 
						dataKey={`value.${index}`} 
						name={_data[0].depth[index] + ' cm'} stroke={colors[index]} dot={null}
					/>
				})}
      </LineChart>

      <LineChart width={800} height={300} data={_data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="timestamp" 
          tickFormatter={formatDate}
        />
        <YAxis 
          label={{ value: "°", position: 'insideTop', dy: 10, dx: -5 }}
        />
        <Tooltip 
          labelFormatter={(value) => {
            return formatDate(value);
          }}
          formatter={(value, name, item, index, payload) => {
						const angle = item.payload.angle[index]
            return value.toFixed(2) + 'mm/s, ' + angle.toFixed(2) + '° ' + getWindType(angle);
          }}  
        />

        { _data[0].value.map((value, index) => {
					return <Line connectNulls type="monotone" 
						dataKey={`angle.${index}`} 
						name={_data[0].depth[index] + ' cm'} stroke={colors[index]} dot={null}
					/>
				})}
      </LineChart>

			<div className='adcp-v-legend'>
        <p style={{color: colors[0]}}>0 cm</p>
        <p style={{color: colors[1]}}>200 cm</p>
        <p style={{color: colors[2]}}>375 cm</p>
        <p style={{color: colors[3]}}>575 cm</p>
      </div>

    </div>
  );
}

export default ADCPVChart