import React, { useCallback, useState } from 'react'
import MenuItemBar from './menuItemBar'
import { changeField, toggleSetField } from './inputReducer'
import { fromLonLat } from 'ol/proj'
import { configuration as appCongiguration } from '../../config'

/**
 * MenuItem Component
 * @constructor
 * This component renders a menu item.
 * @param {object} item - menu item.
 * @param {object} configuration - redux state.
 * @param {object} dispatch - redux dispatch function.
 * @param {object} mapRef - reference to the map.
 */
const MenuItem = ({item, configuration, dispatch, mapRef}) => {

  /** Define a function to toggle a switch value in the configuration state. */
  const toggleValue = useCallback((switchValue, name) => dispatch(changeField(name, !switchValue)), [dispatch])

  /** Determine the current value of the configuration item. */
  const value = item.set
    ? configuration[item.name].has(item.set)
    : configuration?.[item.name]

  /** Define a function to toggle the value of the configuration item. */
  const extend = () =>{
    toggleValue(value, item.name)
  }

  /** Function to handle the click event for the menu item. */
  const onClick = () =>{
    if(item.set){
      /**  Toggle the set field in the configuration. */
      return dispatch(toggleSetField(item.name, item.set))
    } else{
      /** Toggle the value of the configuration item 
       * and center the map on the peninsula if the item is related to the peninsula or has a center property.
       * !value means that the item had been deselected and would be selected by the toggleValue function.
       * */
      if(['showCoastalFlood', 'showCoastSediment', 'showCoastalErosion', 'showCoastCurrents'].includes(item.name)
        && !value
      ){
        // mapRef.current.ol.getView().setCenter(fromLonLat(appCongiguration.peninsulaMap.center))
        // mapRef.current.ol.getView().setZoom(appCongiguration.peninsulaMap.zoom)
        mapRef.current.ol.getView().animate({
          center: fromLonLat(appCongiguration.peninsulaMap.center),
          zoom: appCongiguration.peninsulaMap.zoom,
          duration: 250
        });
      }
      if(item.center && !value){
        // mapRef.current.ol.getView().setCenter(fromLonLat(appCongiguration.peninsulaMap.center))
        // mapRef.current.ol.getView().setZoom(appCongiguration.peninsulaMap.zoom)
        mapRef.current.ol.getView().animate({
          center: fromLonLat(item.center),
          zoom: item.zoom,
          duration: 250
        });
      }
      toggleValue(value, item.name)
    }
  }

  /** If the item has children, render the item and its children. */
  if(item.children){
    return(
      <>
        <MenuItemBar onClick={extend} item={item} isParent={true} isSelected={value}/>
        <br/>
        {value && item.children.map(item => (
          <MenuItem item={item} configuration={configuration} dispatch={dispatch} mapRef={mapRef}/>
        ))}
      </>
    )
  }

  return (
    <>
      <MenuItemBar item={item} onClick={onClick} isSelected={value}/>
      <br/>
    </>
  )
}

export default MenuItem