export const isobandColors = ['#19e4ff', '#009dff', '#006fff', '#003cff', '#051bb0', '#000080', '#5C4346', '#9F580A', '#FF0000', '#BF125D', '#800000', '#1F2937']

const waves = [
	{
		min: 0,
		max: 0.5,
		color: "#19e4ff" 
	},
	{
		max: 1.0,
		color: "#009dff" 
	},
	{
		max: 1.5,
		color: "#006fff" 
	},
	{
		max: 2.0,
		color: "#003cff" 
	},
	{
		max: 2.5,
		color: "#051bb0" 
	},
	{
		max: 3.0,
		color: "#000080" 
	},
	{
		max: 3.5,
		color: "#5C4346"
	},
	{
		max: 4.0,
		color: "#9F580A" 
	},
	{
		max: 4.5,
		color: "#FF0000" 
	},
	{
		max: 5.0,
		color: "#BF125D" 
	},
	{
		max: 5.5,
		color: "#800000" 
	},
	{
		max: 6.0,
		color: "#1F2937",
		unit: 'm' 
	},
]

const level = [
	{
		min: -1.0,
		max: -0.8,
		color: "#e0f2fe" 
	},
	{
		max: -0.6,
		color: "#19e4ff" 
	},
	{
		max: -0.4,
		color: "#009dff" 
	},
	{
		max: -0.2,
		color: "#003cff" 
	},
	{
		max: 0,
		color: "#051bb0" 
	},
	{
		max: 0.2,
		color: "#000080" 
	},
	{
		max: 0.4,
		color: "#5C4346"
	},
	{
		max: 0.8,
		color: "#9F580A" 
	},
	{
		max: 1.0,
		color: "#FF0000" 
	},
	{
		max: 1.2,
		color: "#BF125D" 
	},
	{
		max: 1.4,
		color: "#800000" 
	},
	{
		max: 1.6,
		color: "#1F2937",
		unit: 'm' 
	},
]

const velocities = [
	{
		min: 0,
		max: 0.2,
		color: "#19e4ff" 
	},
	{
		max: 0.4,
		color: "#009dff" 
	},
	{
		max: 0.6,
		color: "#006fff" 
	},
	{
		max: 0.8,
		color: "#003cff" 
	},
	{
		max: 1.0,
		color: "#051bb0" 
	},
	{
		max: 1.2,
		color: "#000080" 
	},
	{
		max: 1.4,
		color: "#5C4346"
	},
]

const bandsDefault = [
  0, 1, 2, 4, 5, 99
]
const colorsDefault = ['#19e4ff', '#009dff', '#006fff', '#003cff', '#051bb0',]

export const isobandLegendData = {
  wladyslawowo_waves: waves,
	jastarnia_waves: waves,
	currents_bottom: velocities,
	currents_surface: velocities,
	level,
	coastal_flood: level,
    default: []
}
