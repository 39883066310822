import GeoJSON from 'ol/format/GeoJSON'

/** This function fetches data from the constructed URL, which is derived from the base 'url' and 'forecast' provided as arguments.
 * It returns a GeoJSON object with features. */
export const fetchLayerData = ({forecast, url}) => {
  return fetch(`${url}/${forecast}`)
    .then(async (res) =>  {
      const data = await res.json()
      const geojson = new GeoJSON().readFeatures(data)
      return geojson
    })
}
