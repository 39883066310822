import React, {
  useContext,
  useReducer,
  useRef,
  useState,
} from "react";
import { fromLonLat, toLonLat } from "ol/proj";
import proj4 from "proj4";
// import countries from '../../data/countries.json'
import "../../App.css";
import { CheckboxList } from "../utils/ChekcboxList";
import { RControl, RFeature, RLayerVector, RMap, ROverlay, RStyle } from "rlayers";
import { ArrowsLayer } from "./RLayaers/ArrowsLayer";
import { BuoyLayer } from "./RLayaers/BuoyLayer";
import { FieldLayer } from "./RLayaers/FieldLayer";
import { BalticLayer } from "./RLayaers/BalticLayer";
import { ProfileLines } from "./RLayaers/ProfileLines";
import { CurrentsLayer } from "./RLayaers/CurrentsLayer";
import RJAWGLight from "./RTiles/RJAWGLight";
import {
  initialMenuData,
  mapConfigurationReducer,
} from "../utils/inputReducer";
import { getRadiusInMeter } from "../utils/icons-intersections";
import { AppContext } from "../../App";
import { TimeLegend } from "../utils/timeLegend";
import { useQuery } from "react-query";
import { fetchForecasts } from "../../utils/fetchForecasts";
import { DateTime } from "luxon";
import PointDataLayer from "./RLayaers/PointDataLayer";
import TextLayer from "./RLayaers/TextLayer";
import { ErosionLayer } from "./RLayaers/ErosionLayer";
import { configuration as appCongiguration } from "../../config";
import { menuButtons } from "./olpMapConfig";
import { configToFieldLayer } from "./utils/olpMap";

proj4.defs(
  "EPSG:2180",
  "+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=GRS80 +units=m +no_defs"
);


const OLMap = () => {

  /** reference to the map. */
  const mapRef = useRef(null);
  /** reference to the scale. */
  const scaleRef = useRef(null);

  /** create useReducer hook to handle the state of the menu buttons. */
  let [configuration, configurationDispatch] = useReducer(
    mapConfigurationReducer,
    initialMenuData
  );

  const {
    showTiles,
    showBaltic,
    showMarker,
    showCoastCurrents,
    showCoastSediment,
    showCoastalErosion,
    markerHighlight,
  } = configuration;

  const { showFieldLayer, fieldLayerToShow, arrowLayerToShow, showArrowsLayer } = configToFieldLayer(configuration);

  /** State to store the radius of the marker in meters. */
  const [radiusInMeters, setRadiusInMeters] = useState(443);

  /** Function to handle the change of the scale and set the marker radius in meters.
   * It is called after the map is rendered.
  */
  const onChange = (e) => {
    /** title of the scale. */
    const renderedHTML = scaleRef.current.ol.renderedHTML_;
    /** pixel width of the scale. */
    const renderedWidth = scaleRef.current.ol.renderedWidth_;
    const radiusInMeters = getRadiusInMeter(renderedHTML, renderedWidth);
    setRadiusInMeters(radiusInMeters);
  };

  const { buoyState, setBuoyState, forecasts, setForecasts, setCurrentForecast, 
      nextForecast, setNextForecast, pointData, setPointData, hoveredBuoyState, setHoveredBuoyState
  } = useContext(AppContext);

  /** State to store the previous key of the forecasts. 
   * previous key equals 0 means to fetch actual forecasts.
   * previous key equals -1 means to fetch previous forecasts.
  */
  const [previousKey, setPreviousKey] = useState(0)

  /** this useQuery hook is used to fetch the forecasts depending on the previous key. 
   * onSuccess function is used to add new forecasts to the forecasts state.
  */
  const { data: _forecasts,  } = useQuery({
    queryKey: ["forecasts", previousKey],
    queryFn: () => fetchForecasts(previousKey),
    onSuccess: (_forecasts) => {
      /** 'newForecasts' will start as a copy of existing forecasts. */
      let newForecasts = [...forecasts]
      /** loop through the fetched forecasts and add them to the 'newForecasts' if they are not already there
       * and if they are not in the past.
      */
      _forecasts.forEach(f => {
        const index = newForecasts.findIndex(nf => nf.key === f.key)
        if (index === -1 && DateTime.now().toMillis() < f.key) {
          newForecasts.push(f)
        }
      })
      newForecasts = newForecasts.sort((a, b) => a.key - b.key)
      setForecasts(newForecasts)

      /** if length of the forecasts state is less than 8 then set previous key to -1 to fetch previous forecasts. */
      if (newForecasts.length < 8 && previousKey === 0) {
        setPreviousKey(-1)
      }

      /** if length of the forecasts state is less than 8 then add dummy forecasts to the forecasts state. */
      while (newForecasts.length < 8 && previousKey === -1) {
        console.log("add new forecast")
        const forecastTime = newForecasts[newForecasts.length - 1].forecastTime.plus({hours: 6})
        newForecasts.push({
          id: `${newForecasts[newForecasts.length - 1].id}-DUMMY`,
          forecastTime,
          value: forecastTime.toFormat('HH:mm'),
          day: forecastTime.toFormat('yyyy-MM-dd'),
          key: forecastTime.toMillis(),
          isDisabled: true,
        })
      }
      if (previousKey === -1) {
        setForecasts(newForecasts)
      }  

      /** set current forecast to the first forecast in the forecasts state to fetch it. */
      setCurrentForecast(newForecasts[0].id)
      /** set next forecast to the second forecast in the nextForecasts state to fetch it to the cache. */
      setTimeout(() => {
        setNextForecast(newForecasts[1].id || null)
      }, 1000)
    },
  })

  /** Function to handle the click on the map.
   * It is used to hide the buoy popup when the map is clicked.
   * It is used to set the coordinates of the marker when the map is clicked.
   */
  const mapClicked = (e) => {
    /** hide the buoy popup. */
    buoyState?.hide();
    setHoveredBuoyState(null)
    /** set the point data state to the coordinates of the clicked point. */
    const coords = e.map.getCoordinateFromPixel(e.pixel);
    const lonlat = toLonLat(coords);
    const geolonlat = fromLonLat(lonlat);
    const _pointData = pointData? {...pointData}: {}
    _pointData.lat = lonlat[1]
    _pointData.lon = lonlat[0]
    _pointData.geolon = geolonlat[0]
    _pointData.geolat = geolonlat[1]
    setPointData(_pointData)
  }

  // TODO license https://marineregions.org/disclaimer.php
  return (
    <div style={{ display: "flex" }}>
      <RMap
        ref={mapRef}
        className="example-map"
        // onRenderComplete={onChange}
        onPostRender={onChange}
        initial={{
          center: fromLonLat(appCongiguration.map.center),
          zoom: appCongiguration.map.zoom,
        }}
        onClick={mapClicked}
      >
        <BalticLayer />

        <TimeLegend/>

        {/* scale panel */}
        <RControl.RScaleLine ref={scaleRef} />
        {/* zoom panel */}
        <RControl.RZoom />
        {/* zoom slider */}
        <RControl.RZoomSlider />

        <div className="menu">
          <CheckboxList
            arr={menuButtons}
            configuration={configuration}
            dispatch={configurationDispatch}
            mapRef={mapRef}
          />
        </div>

        {/* about panel */}
        {showTiles && <RJAWGLight />}

        {showBaltic && <BalticLayer />}
        
        {showFieldLayer && 
          <FieldLayer zIndex={200} layerToShow={fieldLayerToShow} />
        }

        <ArrowsLayer zIndex={500} layerToShow={arrowLayerToShow} configuration={configuration}/>
            
        {(showCoastCurrents || showCoastSediment) && (
          <>
            <ProfileLines zIndex={300} />
            <CurrentsLayer
              zIndex={400}
              magnitudeField={showCoastCurrents ? "u" : "q"}
            />
          </>
        )}
        {(showCoastalErosion) && (
          <>
            <ProfileLines zIndex={300} />
            <ErosionLayer/>
          </>
        )}
        <PointDataLayer />
        {showMarker && (
          <BuoyLayer
            filterList={markerHighlight}
            radiusInMeters={radiusInMeters}
            mapRef={mapRef}
          />
        )}
        <TextLayer />
      </RMap>
    </div>
  );
};

export default OLMap;
