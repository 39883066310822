import { configuration } from "../config";
import {DateTime} from "luxon";

/**
 * This function fetches forecasts from the server and converts them to the format used by the app.
 * @param {number} previousKey - previous key
 */
export async function fetchForecasts(previousKey=0) {
  const response = await fetch(`${configuration.serverUri}/baltic_points?previous=${previousKey}`);
  if (!response.ok) {
    throw new Error('Error fetching points');
  }
  const data = await response.json();
  const forecasts = data.files
  const timestamp = data.timestamp

  const initData = DateTime.fromISO(timestamp, {zone: 'utc'}).toLocal()
  /** sort forecasts by number */
  const _forecasts = forecasts.sort((a, b) => {
    const aNumber = parseInt(a)
    const bNumber = parseInt(b)
    if(aNumber < bNumber) return -1
    if(aNumber > bNumber) return 1
    return 0
  }).map((forecast, index) => {
    const forecastTime = initData.plus({hours: Number(forecast)})
    return {
      id: `${forecast}?previous=${previousKey}`,
      forecastTime: forecastTime,
      value: forecastTime.toFormat('HH:mm'),
      day: forecastTime.toFormat('yyyy-MM-dd'),
      key: forecastTime.toMillis(),
    }
  })
  return _forecasts;
}
