import { RLayerVector } from 'rlayers'
import GeoJSON from 'ol/format/GeoJSON'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Fill, Stroke, Style } from 'ol/style'
import { IsobandLegend } from '../../utils/IsobandLegend'
import { configuration } from '../../../config'
import { AppContext } from '../../../App'
import { useQuery } from 'react-query'
import { fetchLayerData } from '../../../utils/fetchLayerData'
import { isobandLegendData } from '../../utils/isobandLegendData'
import { fieldLayers } from '../utils/olpMap'

/** uri from which to fetch the data according to the layerToShow */
const layerToUri = (layerToShow) => {
  switch (layerToShow) {
    case fieldLayers.level:
      return  `${configuration.serverUri}/baltic_level`
    case fieldLayers.period:
      return `${configuration.serverUri}/baltic_waves_t`
    case fieldLayers.currents_surface:
      return `${configuration.serverUri}/baltic_currents_v`
    case fieldLayers.currents_bottom:
      return `${configuration.serverUri}/baltic_bcurrents_v`
    case fieldLayers.coastal_flood:
      return `${configuration.serverUri}/coastal_flood`
    case fieldLayers.wladyslawowo_waves:
      return `${configuration.serverUri}/waves_port_wl`
    case fieldLayers.jastarnia_waves:
      return `${configuration.serverUri}/waves_port_ja`
    default:
    case 'height':
      return `${configuration.serverUri}/baltic_waves_h`
  }
}

/** legend according to the layerToShow */
const layerToConfig = (layerToShow) => {
  switch (layerToShow) {
    case fieldLayers.level:
      return {
        bands: [390, 450, 500, 600, 650, 9999],
        unit: 'cm'
      }
    case fieldLayers.period:
      return {
        bands: [0, 4, 8, 10, 12, 99],
        unit: 's'
      }
    case fieldLayers.currents_surface:
      return {
        bands: [0, 0.4, 0.8, 1.2, 1.4, 99],
        unit: 'm/s'
      }
    case fieldLayers.coastal_flood:
      return {
        bands: [0],
        unit: 'm'
      }
    case fieldLayers.currents_bottom:
      return {
        bands: [0, 0.4, 0.8, 1.2, 1.4, 99],
        unit: 'm/s'
      }
    default:
    case fieldLayers.height:
      return {
        bands: [0, 1, 2, 4, 5, 99],
        unit: 'm'
      }
  }
}

/** style for the isoband */
const styleForBand = (feature, layerToShow, isobandData) => {

  /** Find the index of the band in the isobandLegendData that corresponds to the feature's high value */
  const bandIndex = isobandLegendData?.[layerToShow]?.findIndex((band, idx, arr) => {
    /** If the feature doesn't have a high value, return true to assign this feature to the first band */
    if(!feature.get('high')) return true
    /** Determine the minimum value of this band, either it's explicitly set, or it's the max of the previous band */
    const min = band.min === undefined ?? arr?.[idx - 1]?.max
    /** Check if the feature's high value is within the band's range */
    return feature.get('high') > min && feature.get('high') <= band.max
  })

  /** Determine the color for the feature based on the found band, or default color if not found */ 
  const color = isobandLegendData?.[layerToShow]?.[bandIndex]?.color || isobandLegendData?.default?.[0]?.color

  return new Style({
    stroke: new Stroke({
      color: 'grey',
      width: 0.5,
    }),
    fill: new Fill({
      color: color
    })
  })
}

/**
 * FieldLayer component
 * @constructor
 * This component renders a layer on the map which displays isobands.
 * It fetches the data from the server and renders it on the map.
 * It also renders the IsobandLegend component.
 * @param {string} layerToShow - layer to show
 * @param {number} zIndex - zIndex of the layer
 */
export const FieldLayer = ({ zIndex = 10, layerToShow }) => {

  const {currentForecast, forecasts, nextForecast} = useContext(AppContext)

  const forecastsUrl = layerToUri(layerToShow)

  /** useQuery hook to fetch the current forecast features from the server
   * It fetches the data from the server and renders it on the map. */
  const {data: features} = useQuery({
    queryKey: [forecastsUrl, currentForecast],
    queryFn: () => fetchLayerData({forecast: currentForecast, url: forecastsUrl}),
    enabled: !!currentForecast && !!forecastsUrl,
    retry: false
  });

  /** useQuery hook to fetch the next forecast features from the server
   * It fetches the data from the server and caches it. */
  const {data: nextForecastFeatures} = useQuery({
    queryKey: [forecastsUrl, nextForecast],
    queryFn: () => fetchLayerData({forecast: nextForecast, url: forecastsUrl}),
    enabled: !!nextForecast && !!forecastsUrl,
    retry: false
  });

  const layerRef = useRef(null);

  /** Effect hook to update the features on the map when the features change. */
  useEffect(() => {
    if (features && layerRef.current) {
      layerRef.current.source.clear();
      layerRef.current.source.addFeatures(features);
    }
  }, [features]);

  /** Create a data structure for the isoband legend */
  const isobandData = features?.map(feature => {
    return {
      min: feature.values_.low,
      max: feature.values_.high,
    }
  }).filter(item=>item.max).sort((a, b) => b.max - a.max)

  /** Add a max value to the last band */
  if(isobandData?.length > 0){
    const isobandDataLast = isobandData[isobandData.length - 1]
    isobandData.push({
      min: isobandDataLast.max,
      max: 9999,
      unit: layerToConfig(layerToShow).unit
    })
  }

  if(!layerToShow || !forecasts) return null

  return <>
    {/* Vector layer to show data on a map, styled by styleForBand, and updated with features from query */}
    <RLayerVector
      features={features}
      ref={layerRef}
      format={new GeoJSON({
        featureProjection: 'EPSG:3857',
        dataProjection: 'EPSG:2180',
      })}
      style={(feature) => styleForBand(feature, layerToShow, isobandData)}
      zIndex={zIndex}>

    </RLayerVector>
    {/* Legend to represent the isoband data visually */}
    <IsobandLegend isobandData={isobandData} layerToShow={layerToShow}/>
  </>
}