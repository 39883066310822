import { useRef, useContext, useState, useEffect } from "react";
import { RFeature, ROverlay, RStyle } from "rlayers";
import { markerTypes } from "../../../utils/buoyConnect";
import RPopupWithOffset from "../Overlays/RPopupWithOffset";
import { DataVisualization } from "../../markers/DataVisualisation";
import { CameraVisualization } from "../../markers/CameraVisualization";
import { AppContext } from "../../../App";

/**
 * BuoyItem Component
 * @constructor
 * This functional component renders a buoy item on a map using the "rlayers" library. It handles 
 * the display of the item, the associated styles, and manages a click-triggered popup using the local 
 * and global application state.
 * @param {Object} item - The data object containing the information of a buoy item.
 */
export const BuoyItem = ({ item }) => {

  /** Reference to the popup which allows us to manipulate or get the value of the popup instance */
  const popupRef = useRef();

  const { buoyState, setBuoyState, pointData, setPointData, hoveredBuoyState, setHoveredBuoyState } = useContext(AppContext);

  const isHovered = hoveredBuoyState === popupRef.current
  
  return (
    <RFeature
      key={`${item.name}`} // TODO better key
      // geometry={new Point(fromLonLat([item.lon, item.lat]))}
      geometry={item.geometry}
      onPointerEnter={() => {setHoveredBuoyState(popupRef.current);}}
      onPointerLeave={() => {setHoveredBuoyState(null);}}
      onClick={(e) => {
        /** If a buoy popup state exists and is associated with the current popupRef
         * Prevent further propagation of the click event
         */
        if (buoyState && (buoyState === popupRef.current) ) {
          e.stopPropagation()

          /** Toggle visibility state of the popup based on its current state */
          if(buoyState.visible === true) {
            buoyState?.hide()
            return setBuoyState(null)
          } else{
            buoyState?.show()
            return setBuoyState(popupRef.current)
          }
        }

        /** If another buoy's popup is visible, hide it */
        buoyState && buoyState?.hide();

        /** Set the global buoy state to the current popupRef, making it the active popup */
        setBuoyState(popupRef.current);

        /** Animate the map to fit around the selected buoy item with specified options */ 
        e.map.getView().fit(e.target.getGeometry().getExtent(), {
          duration: 250,
          maxZoom: 14,
        });

      }}
    >
      {isHovered ?      
        <RStyle.RStyleArray>
          <RStyle.RStyle>
            <RStyle.RCircle radius={16}>
              <RStyle.RFill color={"#ccc"} />
            </RStyle.RCircle>
          </RStyle.RStyle>
          <RStyle.RStyle>
            <RStyle.RCircle radius={12}>
              <RStyle.RFill color={"#3E398F"} />
            </RStyle.RCircle>
          </RStyle.RStyle>
          <RStyle.RStyle>
            <RStyle.RIcon
              src={item.icon}
              anchor={[0.5, 0.5]}
              oppacity={1}
              scale={0.8}
              // size={24}
              // color={'red'}
              color={"white"}
            ></RStyle.RIcon>
          </RStyle.RStyle>
        </RStyle.RStyleArray>
      :
        <RStyle.RStyleArray>
          <RStyle.RStyle>
            <RStyle.RCircle radius={12}>
              <RStyle.RFill color={"#3E398F"} />
            </RStyle.RCircle>
          </RStyle.RStyle>
          <RStyle.RStyle>
            <RStyle.RIcon
              src={item.icon}
              anchor={[0.5, 0.5]}
              oppacity={1}
              scale={0.8}
              // size={24}
              // color={'red'}
              color={"white"}
            ></RStyle.RIcon>
          </RStyle.RStyle>
        </RStyle.RStyleArray>
      }
      {/* The icon is an SVG image that represents the feature on the map
    while an overlay allows us to add a normal HTML element over the feature */}
      {/*<MapErrorBoundary>*/}

      <div>
        {/* Render a clickable popup (RPopupWithOffset) which displays additional information about a buoy */}
        <RPopupWithOffset
          trigger={"click"}
          className="popupContainer"
          offset={{ x: 20, y: 20 }}
          ref={popupRef}
        >
          <h3>{item?.name}</h3>
          {/* <pre> {item && JSON.stringify(zefirSockets[item.wsUrl].measure , null, 4)} </pre> */}

          {/* Conditionally render visualization based on the type of marker */}
          {item.type === markerTypes.CAMERA.id ? (
            <CameraVisualization data={item} />
          ) : (
            <DataVisualization data={item} popupRef={popupRef}/>
          )}
        </RPopupWithOffset>
      </div>
      {/*</MapErrorBoundary>*/}
    </RFeature>
  );
};
