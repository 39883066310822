import { RLayerVector } from 'rlayers'
import GeoJSON from 'ol/format/GeoJSON'
import { useContext, useEffect, useRef, useState } from 'react'
import profiles from '../../../data/profiles.geojson'
import { AppContext } from '../../../App'
import { useQuery } from 'react-query'
import { fetchCoastalCurrents } from '../../../utils/fetchCoastalCurrents'
import { getReducedCoastalCurrents } from '../../../utils/getReducedCoastalCurrents'
import { getPerpendicularProfileAngle, getPointOnLine, getProfileGeometryById, styleForArrow } from '../utils/currentsLayer'

/** 
 * CurrentsLayer Component
 * @constructor
 * This component renders a layer on the map which displays coastal currents or coastal sediments.
 * @param {string} magnitudeField - magnitudeField is either 'u' or 'q' to determine which data to display.
 * @param {number} zIndex - zIndex of the layer.
 */
export const CurrentsLayer = ({ zIndex = 400, magnitudeField }) => {
  const [features, setFeatures] = useState()

  const {currentForecast, setCurrentForecast, setForecasts, nextForecast} = useContext(AppContext)

  /** Effect hook to fetch coastal currents data when currentForecast changes. */
  const {data: currents} = useQuery({
    queryKey: ['coastal_currents', currentForecast],
    queryFn: () => fetchCoastalCurrents(currentForecast),
    enabled: !!currentForecast,
    retry: false,
  });

  /** Effect hook to fetch coastal currents data when nextForecast changes. 
   * This is used to fetch data to cache it for the next forecast.
  */
  useQuery({
    queryKey: ['coastal_currents', nextForecast],
    queryFn: () => fetchCoastalCurrents(nextForecast),
    enabled: !!nextForecast,
    retry: false,
  });

  /** This hook is used to create features from the data fetched by the useQuery hooks above.
   * This hook is called when currents, currentForecast or magnitudeField changes.
   * It creates features from the currents data and sets them to the features state to be rendered on the map.
  */
  useEffect(() => {
    if(currentForecast && currents){
      /** load profiles from a json file */
      fetch(profiles).then(r=>r.json()).then(_profiles => {
        const features = []

        Object.keys(currents).forEach((current) =>{

          /** 
           * get the geometry object of a profile by its id.
           * geometry object contains the coordinates of the profile(start and end points of the line).
           */
          const profileGeometry = getProfileGeometryById({id: current, profiles: _profiles});
          if(!profileGeometry) 
            return console.log('profileGeometry not found')
          const profileCoordinates = profileGeometry?.coordinates;
          const profileCoordinatesStartPoint = profileCoordinates[0];
          const profileCoordinatesEndPoint = profileCoordinates[1];

          /** getReducedCoastalCurrents returns an array of coastal currents data with a reduced number of elements. */
          const _currents = getReducedCoastalCurrents(currents[current], magnitudeField)

          /** iterate over the reduced coastal currents data and create a feature for each element. */
          _currents.forEach((item, index) => {
            const xPercent = item.x / _currents[_currents.length-1].x
            const value = magnitudeField === 'u' ? item.u : item.q
            const feature = { 
              "type": "Feature", 
              "properties": { 
                "u": item.u.toFixed(1),
                // "az_wek": item.d,
                // "az_wek": item.hs,
                "az_wek": 
                  getPerpendicularProfileAngle(profileCoordinatesStartPoint, profileCoordinatesEndPoint) + 
                  (value >= 0 ? 180 : 0),
                "q": item.q.toFixed(1),
              }, 
              "geometry": { 
                "type": "Point", 
                "coordinates": getPointOnLine(profileCoordinatesStartPoint, profileCoordinatesEndPoint,  xPercent) 
              } 
            }
            features.push(feature)
          })  
        })

        /** set features */
        const geojson = new GeoJSON().readFeatures({
          "type": "FeatureCollection",
          "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::3857" } },
          features
        })
        setFeatures(geojson);

      })
    }
  }, [currentForecast, magnitudeField, currents])

  const layerRef = useRef(null);

  /** Effect hook to update the features of the layer when features state changes. */
  useEffect(() => {
    if (features && layerRef.current) {
      layerRef.current.source.clear();
      layerRef.current.source.addFeatures(features);
    }
  }, [features]);

//   const arrowStyle = useCallback((feature, _) => {
//     const az = feature.get('az_wek')
//     const u = feature.get('u')
//     return <RIcon
//       anchorXUnits={'fraction'}
//       // anchorYUnits={'pixels'}
//       scale={Math.max (u , 0.3)}
//       src={arrowmarker}
//
//       rotation={az * Math.PI / 180}
//     />
//
// V  }, [])

  return features && <RLayerVector features={features}
    format={GeoJSON} zIndex={zIndex}
    style={styleForArrow({ magnitudeField })}
    ref={layerRef}
    // style={arrowStyle}
    // declutter={true}
  >
    {/*<RStyle render={styleForArrow}/>*/}
    {/*<RStyle>*/}
    {/*  <RIcon src={arrowmarker}*/}
    {/*                anchor={[0.5, 1]}*/}
    {/*                oppacity={1} scale={1}*/}

    {/*  />*/}
    {/*</RStyle>*/}
  </RLayerVector>
}
