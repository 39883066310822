import React, { createContext, useEffect, useState } from "react";
import "./App.css";
import OLMap from "./components/map/OLMap";
import { QueryClientProvider, QueryClient } from 'react-query';
import Stats from "./components/graph/stats";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";

export const AppContext = createContext();

const queryClient = new QueryClient({ defaultOptions: {
  queries: {
    cacheTime: 20 * 60 * 1000, // Cache data for 10 minutes after the component is unmounted
    staleTime: 10 * 60 * 1000, // Data is considered fresh for 5 minutes
  },
}})

/**
 * App Component
 * @constructor
 * This component renders the app.
 */
function App() {

  /** buoyState contains a reference for a popup of buoyItem. It is used to show/hide the popup,  
   * to determine which popup is currently active, to close the active popup when another is opened.
   * It also reset the point data when a popup is opened.
  */
  const [buoyState, setBuoyState] = useState(null)

  /** State to track whether the mouse is hovering over the buoy item */
  const [hoveredBuoyState, setHoveredBuoyState] = useState(null)

  /** currentForecast contains data about the current forecast. */
  const [currentForecast, setCurrentForecast] = useState(null)

  /** nextForecast contains data about the next forecast. */
  const [nextForecast, setNextForecast] = useState(null)

  /** forecasts contains data about forecasts. 
   * It is used to generate the forecast time panel.
   * forecast is an object with the following properties:
   * id, forecastTime, value in format('HH:mm'), day in Format('yyyy-MM-dd'), key in millis, isDisabled
  */
  const [forecasts, setForecasts] = useState([])

  /** pointData contains data about the point that is currently selected. */
  const [pointData, setPointData] = useState(null)

  const contextValue = {
    buoyState, setBuoyState,
    currentForecast, setCurrentForecast,
    nextForecast, setNextForecast,
    forecasts, setForecasts,
    pointData, setPointData,
    hoveredBuoyState, setHoveredBuoyState
  }

  return (
      <div className="App">
        <QueryClientProvider client={queryClient}>
          <AppContext.Provider value={contextValue}>
            <BrowserRouter>
              <Routes>
                <Route path="/" exact element={<OLMap/>} />
                <Route path="/:id" element={<Stats/>} />
              </Routes>
            </BrowserRouter>
          </AppContext.Provider>
        </QueryClientProvider>
      </div>
  );
}

export default App;
