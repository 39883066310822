import markerBuoy from "../assets/markerBuoy.svg";
import markerADCP from "../assets/markerADCP.svg";
import markerMeteo from "../assets/markerMeteo.svg";
import markerEdge from "../assets/markerEdge.svg";
import markerCamera from "../assets/markerCamera.svg";
import { format, fromUnixTime } from "date-fns";
import { getWindAngle, getWindSpeed, getWindType, getWindView } from "./wind";

/** This file contains the configuration of the buoys. */ 
export const dataTypes = {
  GEO: {
    format: (x) => `${x?.toFixed(5)}°`,
    id: "GEO",
  },
  GEO_LON: {
    format: (x) => {
      if (x) {
        return `${x?.toFixed(5)}°`
      } else {
        return '---'
      }
    },
    id: "GEO_LON",
    title: "Długość geograficzna",
    tooltip: "Długość geograficzna w st.",
    units: "°",
  },
  GEO_LAT: {
    format: (x) => {
      if (x) {
        return `${x?.toFixed(5)}°`
      } else {
        return '---'
      }
    },
    id: "GEO_LAT",
    title: "Szerokość geograficzna",
    tooltip: "Szerokość geograficzna w st.",
    units: "°",
  },
  VELOCITY: {
    format: (x) => `${x?.toFixed(2)} m/s`,
    id: "VELOCITY",
    title: "Liczba rzeczywista, moduł średniej prędkości wiatru w m/s",
    tooltip: "Liczba rzeczywista, moduł średniej prędkości wiatru w m/s",
    units: "m/s",
  },
  VELOCITY_S: {
    format: (x) => `${x?.toFixed(2)} m/s`,
    id: "VELOCITY_S",
    title: "Południowa składowa prędkości",
    tooltip: "Składowa południowa średniego wektora prędkości wiatru w m/s",
    units: "m/s",
  },
  VELOCITY_E: {
    format: (x) => `${x?.toFixed(2)} m/s`,
    id: "VELOCITY_E",
    title: "Wschodnia składowa prędkości",
    tooltip: "Składowa wschodnia średniego wektora prędkości wiatru w m/s",
    units: "m/s",
  },
  VELOCITY_GS: {
    format: (x) => `${x?.toFixed(2)} m/s`,
    id: "VELOCITY_GS",
    title: " Składowa południowa wektora porywu wiatru w m/s",
    tooltip: " Składowa południowa wektora porywu wiatru w m/s",
    units: "m/s",
  },
  VELOCITY_GE: {
    format: (x) => `${x?.toFixed(2)} m/s`,
    id: "VELOCITY_GE",
    title: "Składowa wschodnia wektora porywu wiatru w m/s",
    tooltip: "Składowa wschodnia wektora porywu wiatru w m/s",
    units: "m/s",
  },
  VELOCITY_CALC: {
    format: (x, data) => {
      const we = Number(data.clima.we);
      const ws = Number(data.clima.ws);
      const v = Math.sqrt(we * we + ws * ws);
      return `${v.toFixed(2)} m/s`;
    },
    id: "VELOCITY_CALC",
    title: "Prędkość wiatru",
    tooltip: "Liczba rzeczywista, moduł średniej prędkości wiatru w m/s",
    units: "m/s",
  },
  VELOCITY_DIRECTION_CALC: {
    format: (x, data) => {
      const we = Number(data.clima.we);
      const ws = Number(data.clima.ws);
      let angle = getWindAngle({we, ws});

      return (
        <>{angle.toFixed(2)}° <span style={{
              transform: `rotate(${-angle+90}deg)`,
              position: 'absolute'
            }}
          >&larr;</span> {` `}
          <span style={{paddingLeft: "20px"}}>{getWindType(angle)}</span>
        </>
      )
    },
    id: "VELOCITY_DIRECTION_CALC",
    title: "Kierunek wiatru (strona od)",
    tooltip: "Liczba rzeczywista, kierunek średniej prędkości wiatru w st. względem północy.",
    units: "°",
  },
  VELOCITY_DIRECTION: {
    format: (x, data) => {
      let angle = `${x?.toFixed(2)}°`;
      return (
        <>{angle}</>
      )
    },
    id: "VELOCITY_DIRECTION",
    title: "Kierunek wiatru (strona od)",
    tooltip: "Liczba rzeczywista, kierunek średniej prędkości wiatru w st. względem północy.",
  },
  TEMP: {
    format: (x) => `${x?.toFixed(1)}°C`,
    id: "TEMP",
    title: 'Temperatura powietrza',
    tooltip: 'Średnia temperatura powietrza w st.C',
    units: "°C",
  },
  TEMP_R: {
    format: (x) => `${x?.toFixed(1)}°C`,
    id: "TEMP_R",
    title: 'Teperatura punktu rosy',
    tooltip: 'Średnia temperatura punktu rosy w st.C',
    units: "°C",
  },
  TEMP_SEA: {
    format: (x) => `${x?.toFixed(1)}°C`,
    id: "TEMP_SEA",
    title: 'Temperatura wody',
    tooltip: 'Średnia temperatura wody w st.C',
    units: "°C",
    symbol: <>T<span className="symbol">w</span></>
  },
  TEMP_AIR: {
    format: (x) => `${x?.toFixed(1)}°C`,
    id: "TEMP_AIR",
    title: 'Temperatura powietrza',
    tooltip: 'Temperatura powierza w st.C',
    units: "°C",
  },
  ANGLE_WAVE: {
    format: (x) => `${x?.toFixed(1)}°`,
    id: "ANGLE_WAVE",
    title: "Kierunek fali znacznej",
    // tooltip: "Kierunek fali znaczącej hm0 w st. względem północy",
    units: "°",
    symbol: <>Dir<span className="symbol">p</span></>
  },
  LENGTH_HMAX: {
    format: (x) => `${x?.toFixed(2)} m`,
    id: "LENGTH_HMAX",
    units: "m",
    title:  "Wysokość fali maksymalnej",
    tooltip: "maksymalna rzędna grzbietów fal.",
    symbol: <>H<span className="symbol">max</span></>
  },
  LENGTH_M: {
    format: (x) => `${x?.toFixed(2)} m`,
    id: "LENGTH_M",
    title: "Wysokość fali znacznej",
    tooltip: "statystyczna, reprezentatywna wysokość fali, obliczana na podstawie widma falowania",
    units: "m",
    symbol: <>H<span className="symbol">m0</span></>
  },
  LENGTH_M100: {
    format: (x) => x && `${(x / 100).toFixed(2)} m`,
    id: "LENGTH_M100",
    title: "Maksymalna wysokość fali",
    tooltip: "Maksymalna wysokość fali w okresie uśredniania obliczona z szeregu czasowego, podana w cm.",
    units: "m",
  },
  LENGTH_SEAL: {
    format: (x) => x && `${(x / 100).toFixed(2)} m`,
    id: "LENGTH_SEAL",
    title: "Poziom morza",
    tooltip: "Nie kalibrowany poziom morza",
    units: "m",
  },
  TIME: {
    format: (x) => `${x.toFixed(1)} s`,
    id: "TIME",
    units: "s",
    title: "Okres fali",
    tooltip: "Okres fali",
  },
  TIME_S: {
    format: (x) => `${x?.toFixed(1)} s`,
    id: "TIME_S",
    title: "Okres fali",
    tooltip: "Okres fali",
    units: "s",
    symbol: <>T<span className="symbol">z</span></>
  },
  TIME_HMAX: {
    format: (x) => `${x?.toFixed(1)} s`,
    id: "TIME_HMAX",
    title: "Okres fali maksymalnej",
    tooltip: "okres indywidualnej fali na postawie której określono Hmax",
    units: "s",
    symbol: <>T<span className="symbol">Hmax</span></>
  },
  TIME_Z: {
    format: (x) => `${x?.toFixed(1)} s`,
    id: "TIME_Z",
    title: "średni okres fali",
    tooltip: "średni okres wyznaczony na podstawie wychyleń swobodnej powierzchni",
    units: "s",
    symbol: <>T<span className="symbol">z</span></>
  },
  TIME_PW: {
    format: (x) => `${x?.toFixed(1)} s`,
    id: "TIME_PW",
    title: "Okres piku",
    tooltip: "okres, dla którego występuje maksimum widma",
    units: "s",
    symbol: <>T<span className="symbol">p</span></>
  },
  HUMIDITY: {
    format: (x) => `${x?.toFixed(0)} %`,
    id: "HUMIDITY",
    title: "Wilgotność względna",
    tooltip: "Średnia wilgotność względna w procentach",
    units: "%",
  },
  HUMIDITY_R: {
    format: (x) => `${x?.toFixed(0)} %`,
    id: "HUMIDITY_R",
    title: "Wilgotność względna",
    tooltip: "Wilgotność względna w procentach",
    units: "%",
  },
  PRESSURE: {
    format: (x) => `${x?.toFixed(1)} hPa`,
    id: "PRESSURE",
    title: "Ciśnienie atmosferyczne",
    tooltip: "Średnia ciśnienie atmosferyczne w hPa",
    units: "hPa",
  },
  PRESSURE_A: {
    format: (x) => `${x?.toFixed(1)} hPa`,
    id: "PRESSURE_A",
    title: "Cisnienie atmosferyczne",
    tooltip: "Cisnienie atmosferyczne nie korygowane do poziomu morza podane w hPa",
    units: "hPa",
  },
  VOLTAGE: {
    format: (x) => `${x?.toFixed(1)} V`,
    id: "VOLTAGE",
    title: "Napięcia zasilania",
    tooltip: "Napięcie zasilania w Voltach",
    units: "V",
  },
  TIMESTAMP: {
    format: (x) => x
      ? format(fromUnixTime(x), "yyyy.MM.dd HH:mm")
      : "",
    id: "TIMESTAMP",
    title: "pomiar dokonano",
    // tooltip: "Szereg czasowy falowania przez 10 minut",
    tooltip: (value, measure) => `Strefa czasowa ${format(fromUnixTime(value), "O")}`,
    hideSymbol: true,
    hideGraph: true,
  },
  WAVE_HEIGHT: {
    // ??
    format: (x) => (
      <div style={{ fontSize: 3 }}>{x?.toString().replace(/,/g, ", ")}</div>
    ),
    id: "WAVE_HEIGHT",
    title: "Szereg czasowy falowania",
    units: "m",
  },
  ADCP_VELOCITIES: {
    // ??
    format: (x) => {
      if(!x) return null;
      try {
        const speed = getWindSpeed({ we: x[0][1], ws: x[0][2] })
        const angle = getWindAngle({ we: x[0][1], ws: x[0][2] })
        const view = getWindView(angle, { paddingLeft: "12px" })
        const speed7 = getWindSpeed({ we: x[7][0], ws: x[7][1] })
        const angle7 = getWindAngle({ we: x[7][0], ws: x[7][1] })
        const view7 = getWindView(angle7, { paddingLeft: "12px" })
        const speed14 = getWindSpeed({ we: x[14][0], ws: x[14][1] })
        const angle14 = getWindAngle({ we: x[14][0], ws: x[14][1] })
        const view14 = getWindView(angle14, { paddingLeft: "12px" })
        const speed22 = getWindSpeed({ we: x[22][0], ws: x[22][1] })
        const angle22 = getWindAngle({ we: x[22][0], ws: x[22][1] })
        const view22 = getWindView(angle22, { paddingLeft: "12px" })
        return <div style={{ fontSize: 10 }}>
          <div>{speed.toFixed(1)} mm/s {view}</div>
          <div>{speed7.toFixed(1)} mm/s {view7}</div>
          <div>{speed14.toFixed(1)} mm/s {view14}</div>
          <div>{speed22.toFixed(1)} mm/s {view22}</div>
        </div>
      }catch (e){
        return <div>Brak danych</div>
      }
    },
    id: "ADCP_VELOCITIES",
    title: "Predkosci pradu morskiego",
    tooltip: <>Predkosci na glebokosciach: 25&nbsp;cm, 200&nbsp;cm, 375&nbsp;cm, 575&nbsp;cm</>,
    units: "mm/s",
  },
};

const wrongDataAlertWaveBouy = (measure) => {
      return {
        sensorStatus: measure?.wsum?.hmax > 7 || measure?.ssum?.tz < 1.6 ||
          measure?.ssum?.tz > 30 || measure?.wsum?.hmax < measure?.ssum?.hm0,
        message: 'Warunki pogodowe nie pozwalają na poprawny pomiar',
      }
    }

/** This file contains the configuration of the buoys. */
export const markerTypes = {
  WAVE_BUOY: {
    icon: markerBuoy,
    id: "WAVE_BUOY",
    wrongDataAlert: wrongDataAlertWaveBouy,
    dataWrongRanges: (data) => {
      /** assumes sorted data */
      // sort data by timestamp
      // TODO sort by t?
      const ranges = []
      let current_range = {active: false }
      // console.log('ranges wrong', data)
      let cache = { wsum: {}, ssum: {} }
      data.forEach((item) => {
        // console.log('ranges before cache', cache, item,current_range )
        cache.wsum = { ...cache.wsum, ...item.wsum }
        cache.ssum = { ...cache.ssum, ...item.ssum }
        if (wrongDataAlertWaveBouy(cache).sensorStatus) {
          if (!current_range.active ) {
            current_range.data = { start: item.timestamp, end: null }
            current_range.active = true
            // console.log('ranges startcont', cache, item,current_range )
          }
        } else {
          if (current_range.active) {
            current_range.data.end = item.timestamp
            ranges.push(current_range.data)
            current_range.active = false
            // console.log('ranges end', cache, item, current_range )

          }
        }
      })
      if (current_range.active) {
        ranges.push(current_range.data)
      }
      return ranges
    },
    dataFilter: {
      wsum: {
        hmax: dataTypes.LENGTH_HMAX.id,
        thmax: dataTypes.TIME_HMAX.id,
      },
      ssum: {
        tz: dataTypes.TIME_Z.id,
        hm0: dataTypes.LENGTH_M.id,
        dirp: dataTypes.ANGLE_WAVE.id,
        tpw: dataTypes.TIME_PW.id,
      },
      bsum: {
        tsea: dataTypes.TEMP_SEA.id,
      },
    },
    filter: {
      wsum: {
        t: dataTypes.TIMESTAMP.id,
        hmax: dataTypes.LENGTH_HMAX.id,
        thmax: dataTypes.TIME_HMAX.id,
      },
      ssum: {
        tz: dataTypes.TIME_Z.id,
        hm0: dataTypes.LENGTH_M.id,
        dirp: dataTypes.ANGLE_WAVE.id,
        tpw: dataTypes.TIME_PW.id,
      },
      bsum: {
        tsea: dataTypes.TEMP_SEA.id,
        lon: dataTypes.GEO_LON.id,
        lat: dataTypes.GEO_LAT.id,
      },
    },
  },
  BEACH_BUOY: {
    icon: markerBuoy,
    id: "BEACH_BUOY",
    filter: {},
  },
  EDGE_SENSOR: {
    icon: markerEdge,
    id: "EDGE_SENSOR",
    filter: {
      wave: {
        t: dataTypes.TIMESTAMP.id,
        // tz: dataTypes.TIME_Z.id,
        hmax: dataTypes.LENGTH_HMAX.id,
        // ap: dataTypes.PRESSURE_A.id,
        // tp: dataTypes.TEMP_AIR.id,
        // rh: dataTypes.HUMIDITY_R.id,
        // vcc: dataTypes.VOLTAGE.id,
        seal: dataTypes.LENGTH_SEAL.id,
        // z: dataTypes.WAVE_HEIGHT.id,
      },
    },
  },
  CAMERA: {
    icon: markerCamera,
    id: "CAMERA",
    filter: {},
  },
  OTHER: {
    icon: markerEdge,
    id: "OTHER",
    filter: {},
  },
  METEO_STATION: {
    icon: markerMeteo,
    id: "METEO_STATION",
    filter: {
      clima: {
        ws: dataTypes.VELOCITY_S.id,
        we: dataTypes.VELOCITY_E.id,
        // wgs: dataTypes.VELOCITY_GS.id,
        // wge: dataTypes.VELOCITY_GE.id,
        wv: dataTypes.VELOCITY_CALC.id,
        wd: dataTypes.VELOCITY_DIRECTION_CALC.id,
        // wv: dataTypes.VELOCITY.id,
        // wd: dataTypes.VELOCITY_DIRECTION.id,
        tp: dataTypes.TEMP.id,
        tpr: dataTypes.TEMP_R.id,
        rh: dataTypes.HUMIDITY.id,
        ap: dataTypes.PRESSURE.id,
      },
    },
  },
  ADCP: {
    icon: markerADCP,
    id: "ADCP",
    filter: {
      adcp: {
        t: dataTypes.TIMESTAMP.id,
        // ap: dataTypes.PRESSURE.id,
        // tp: dataTypes.TEMP.id,
        // rh: dataTypes.HUMIDITY.id,
        v: dataTypes.ADCP_VELOCITIES.id,
        // vcc: dataTypes.VOLTAGE.id,
      },
    },
  },
};

const BASE_API_URL = "https://api.bathymetr.com/v1";

const toImageUrl = (location, camera) =>
  `${BASE_API_URL}/screens/${location}/${camera}`;
const toMeasureUrl = (location, camera) =>
  `${BASE_API_URL}/measure/${location}/newest`;

/** Configuration of the buoys. */
export const buoysConfig = [
  // Kuźnica 1 - Punkt 1 kamera meteo - GPS: 54.738041, 18.575143
  // Kuźnica 1 - Punkt 2 sonda krawędziowa - GPS: 54.738945, 18.576369
  // Kuźnica 2 - Punkt 3 ADCP zainstalowane nie działa - GPS: 54.740059, 18.577605
  // Kuźnica 2 - Punkt 4 boja falowa - GPS: 54.744656, 18.583387 (roughly, nie ma punktów odniesienia na obrazku za bardzo)
  {
    name: "Kuznica",
    ws: "ws://zefir.ibwpan.gda.pl/hel-ku/ws",
    helapi: 'hel-ku',
    points: [
      {
        name: "Kuznica - Kamera",
        id: "hel-ku-camera",
        lat: 54.737601,
        lon: 18.575253, //Original lat: 54.738041, lon: 18.575143,
        type: markerTypes.CAMERA.id,
        imageUrl: toImageUrl("hel-ku", "kamera1"),
        streamUrl: 'https://webcamera.pl/preview.php?app=ibwpan_cam_bb84c2&s=ibwpan_cam_bb84c2.stream&rtmp=oktastream3.webcamera.pl'
      },
      {
        name: "Kuznica - stacja pogodowa",
        id: "hel-ku-meteo",
        lat: 54.738121,
        lon: 18.57374, //Original lat: 54.738040, lon: 18.575140,
        type: markerTypes.METEO_STATION.id,
        measureUrl: toMeasureUrl("hel-ku"),
      },
      {
        name: "Kuznica - Sonda krawędziowa",
        id: "hel-ku-edge",
        lat: 54.738945,
        lon: 18.576369,
        type: markerTypes.EDGE_SENSOR.id,
        measureUrl: toMeasureUrl("hel-ku"),
      },
      {
        name: "Kuznica - ADCP",
        id: "hel-ku-adcp",
        lat: 54.740059,
        lon: 18.577605,
        type: markerTypes.ADCP.id,
        notInstalled: false,
        measureUrl: toMeasureUrl("hel-ku"),
      },
      {
        name: "Kuznica - boja falowa",
        id: "hel-ku-buoy",
        lat: 54.75281,
        lon: 18.58779,
        type: markerTypes.WAVE_BUOY.id,
        measureUrl: toMeasureUrl("hel-ku"),
      },
    ],
  },
  // * meteo? Jastrzębia Góra rys 1 - Punkt 1 szczyt Klifu - GPS: 54.833890, 18.327866
  // * meteo? Jastrzębia Góra rys 1 - Punkt 2 podstawa Klifu - GPS: 54.834416, 18.327799
  // Jastrzębia Góra rys 1 - Punkt 3 w wodzie głębokość 0.7 m - GPS: 54.835567, 18.328333
  //
  // Jastrzębia Góra rys 2 - Punkt 4 ADCP nie zainstalowane - GPS: 54.839093, 18.330342
  // * Jastrzębia Góra rys 2 - Punkt 5 Boja falowa 800m od brzegu - GPS: 54.844397, 18.330999
  //   (*pytanie na ile rysunek jest dokładny. Bo "800 m. od brzegu" wychodzi w: 54.841878, 18.330661)
  {
    name: "Jastrzębia Góra",
    ws: "ws://zefir.ibwpan.gda.pl/hel-jg/ws",
    lat: 54.85021,
    lon: 18.3341,
    helapi: "hel-jg",
    points: [
      {
        name: "Jastrzębia Góra - Szczyt klifu",
        id: "hel-jg-edge",
        lat: 54.83389,
        lon: 18.327866,
        type: markerTypes.METEO_STATION.id,
        measureUrl: toMeasureUrl("hel-jg"),
      },
      // {
      //   name: "Jastrzębia Góra - Podstawa klifu",
      //   id: "hel-jg-camera",
      //   lat: 54.834416,
      //   lon: 18.327799,
      //   type: markerTypes.CAMERA.id,
      //   imageUrl: toImageUrl("hel-jg", "kamera1"),
      //   streamUrl: 'https://webcamera.pl/preview.php?app=ibwpan_cam_f37ec0&s=ibwpan_cam_f37ec0.stream&rtmp=oktastream3.webcamera.pl'
      // },
      // {
      //   name: "Jastrzębia Góra",
      //   id: "hel-jg-camera2",
      //   lat: 54.83434825697365,
      //   lon: 18.329340885575363,
      //   type: markerTypes.CAMERA.id,
      //   imageUrl: toImageUrl("hel-jg", "kamera2"),
      //   streamUrl: 'https://webcamera.pl/preview.php?app=ibwpan_cam_76310a&s=ibwpan_cam_76310a.stream&rtmp=oktastream3.webcamera.pl'
      // },
      {
        name: "Jastrzębia Góra - W wodzie 0.7m",
        id: "hel-jg-water",
        lat: 54.835567,
        lon: 18.328333,
        type: markerTypes.EDGE_SENSOR.id,
        measureUrl: toMeasureUrl("hel-jg"),
      },
      {
        lat: 54.839093,
        lon: 18.330342,
        name: "Jastrzębia Góra - ADCP",
        id: "hel-jg-adcp",
        type: markerTypes.ADCP.id,
        notInstalled: false,
        measureUrl: toMeasureUrl("hel-jg"),
      },
      {
        lat: 54.85021,
        lon: 18.3341,
        name: "Jastrzębia Góra - Boja falowa 800m od brzegu",
        id: "hel-jg-buoy",
        type: markerTypes.WAVE_BUOY.id,
        measureUrl: toMeasureUrl("hel-jg"),
      },
    ],
  },
  // Jastarnia 1 - Punkt 1 Kamera Meteo - GPS: 54.694856, 18.671903
  // Jastarnia 1 - Punkt 2 Sonda krawędziowa - GPS: 54.694286, 18.670077
  // Jastarnia 2 - Punkt 3 Adcp zinstalowana rama - GPS: 54.686568, 18.658752
  {
    name: "Jastarnia",
    ws: "ws://zefir.ibwpan.gda.pl/hel-ja/ws",
    lat: 54.694339,
    lon: 18.669934,
    helapi: "hel-ja",
    points: [
      {
        name: "Jastarnia - Kamera",
        id: "hel-ja-camera",
        lat: 54.694856,
        lon: 18.672033, //Original lat: 54.694856, lon: 18.671903,
        type: markerTypes.CAMERA.id,
        imageUrl: toImageUrl("hel-ja", "kamera1"),
        streamUrl: 'https://webcamera.pl/preview.php?app=ibwpan_cam_307607&s=ibwpan_cam_307607.stream&rtmp=oktastream3.webcamera.pl'
      },
      {
        name: "Jastarnia - Stacja pogodowa",
        id: "hel-ja-meteo",
        lat: 54.694326,
        lon: 18.6726, //Original lat: 54.694856, lon: 18.671900,
        type: markerTypes.METEO_STATION.id,
        measureUrl: toMeasureUrl("hel-ja"),
      },
      {
        name: "Jastarnia - Sonda krawędziowa",
        id: "hel-ja-edge",
        lat: 54.694286,
        lon: 18.670077,
        type: markerTypes.EDGE_SENSOR.id,
        measureUrl: toMeasureUrl("hel-ja"),
      },
      {
        name: "Jastarnia - ADCP",
        id: "hel-ja-adcp",
        lat: 54.686568,
        lon: 18.658752,
        type: markerTypes.ADCP.id,
        measureUrl: toMeasureUrl("hel-ja"),
      },
    ],
  },
  // Puck 1 - Punkt 1 kamera wzdłuż brzegu - GPS: 54.725037, 18.402527
  // Puck 1 - Punkt 2 Sonda krawędzowa - GPS: 54.725711, 18.403235
  // Puck 2 - Punkt 3 ADCP nie zainstalowane - GPS: 54.727013, 18.404280 (roughly)
  {
    name: "Puck",
    ws: "ws://zefir.ibwpan.gda.pl/hel-pu/ws",
    helapi: "hel-pu",
    lat: 54.725702,
    lon: 18.403447,
    points: [
      {
        name: "Puck - Kamera ",
        id: "hel-pu-camera",
        lat: 54.725037,
        lon: 18.401527, //original lat: 54.725037, lon: 18.402527
        type: markerTypes.CAMERA.id,
        imageUrl: toImageUrl("hel-pu", "kamera1"),
        streamUrl: 'https://webcamera.pl/preview.php?app=ibwpan_cam_6f91ec&s=ibwpan_cam_6f91ec.stream&rtmp=oktastream3.webcamera.pl'
      },
      {
        name: "Puck - Kamera ",
        lat: 54.724497,
        id: "hel-pu-camera2",
        lon: 18.402927, //original lat: 54.725037, lon: 18.402527
        type: markerTypes.CAMERA.id,
        imageUrl: toImageUrl("hel-pu", "kamera2"),
        streamUrl: 'https://webcamera.pl/preview.php?app=ibwpan_cam_f48945&s=ibwpan_cam_f48945.stream&rtmp=oktastream3.webcamera.pl'
      },
      {
        name: "Puck - stacja pogodowa ",
        id: "hel-pu-meteo",
        lat: 54.725977,
        lon: 18.4018, //original lat: 54.725077, lon: 18.402520,
        type: markerTypes.METEO_STATION.id,
        measureUrl: toMeasureUrl("hel-pu"),
      },
      {
        name: "Puck - Sonda krawędziowa",
        id: "hel-pu-edge",
        lat: 54.725511,
        lon: 18.403225,
        type: markerTypes.EDGE_SENSOR.id,
        measureUrl: toMeasureUrl("hel-pu"),
      },
      {
        name: "Puck - ADCP",
        id: "hel-pu-adcp",
        lat: 54.727013,
        lon: 18.40428,
        notInstalled: false,
        type: markerTypes.ADCP.id,
        measureUrl: toMeasureUrl("hel-pu"),
      },
    ],
  },
  {
    name: "Lubiatowo",
    lat: 54.813970,
    lon: 17.847436,
    points: [
      {
        name: "Lubiatowo - Kamera ",
        id: "lubiatowo-camera",
        lat: 54.812679134902105,
        lon: 17.839798399700825,
        type: markerTypes.CAMERA.id,
        imageUrl: "https://imageserver.webcamera.pl/miniaturki/lubiatowo_cam_543fb2.stream.jpg",
      },
    ]
  }
];
// hej-jg {lat: 54850210, lon: 18334100}
// buoyConnect.js:23 ws://zefir.ibwpan.gda.pl/hel-jg/ws {lat: 54850210, lon: 18334100}
// buoyConnect.js:23 ws://zefir.ibwpan.gda.pl/hel-ku/ws {lat: 54752810, lon: 18587790}

/* Dane:
ws - wiatr ? m/s
we -  wiatr - podmuch ? m/s
tp - temp C
tpr - temp punktu rosy ? C
rh - wilgotnosc wzgledna
ap - abs pressure

WSum
hmax - wysokosc fali cm
tHmax - okres fali  s

SSum
hm0 m
Tz s
Dirp  stopnie
Tp s


BSum
Tseam temperatura morza
Lat
Lon

 */
// export const zefirSockets = {}
//
// export const connectBuoys = () => {
//   for (let { ws, name, lat, lon, ...bProps } of buoysConfig) {
//
//     let webSocket = new WebSocket(ws)
//     webSocket.onopen = () => {
//       webSocket.onmessage = (event) => {
//         // console.log(buoyUrl, event.data)
//         let data = JSON.parse(event.data)
//         if (data.measure && data.measure.lat) {
//           // status message has lat long
//           // console.log(ws, { lat: data.measure.lat, lon: data.measure.lon })
//           zefirSockets[ws].info = {
//             ...zefirSockets[ws].info,
//             lat: data.measure.lat / 1000000,
//             lon: data.measure.lon / 1000000,
//             ws,
//             ...bProps
//           }
//         }
//         if (data.measure) {
//           zefirSockets[ws].measure = { ...zefirSockets[ws].measure, ...data.measure }
//         }
//
//       }
//       webSocket.send(JSON.stringify({ cmd: 'status' }))
//     }
//     zefirSockets[ws] = {
//       info: { name, lat, lon, ...bProps },
//       measure: {},
//       webSocket
//     }
//   }
//   console.log('sockets', zefirSockets)
// }

/*
let status = {
  'measure': {
    'tsea': 116,
    'lat': 54753220,
    'lon': 18587240,
    'dx': 5063,
    'dy': -5445,
    'ws': 2,
    'we': 90,
    'tp': 123,
    'rh': 990,
    'ap': 10091,
    'tpr': 122,
    'wv': 90,
    'wd': 910,
    'tpw': 20,
    'dirp': 1013,
    'hm0': 2,
    'tz': 22,
    'hmax': 300,
    'thmax': 20
  }, 'saved': {
    'tsea': null,
    'lat': null,
    'lon': null,
    'dx': null,
    'dy': null,
    'ws': null,
    'we': null,
    'tp': null,
    'rh': null,
    'ap': null,
    'tpr': null,
    'wv': null,
    'wd': null,
    'tpw': null,
    'dirp': null,
    'hm0': null,
    'tz': null,
    'hmax': null,
    'thmax': null
  }, 'alive': {
    'climaport': true,
    'clima': true,
    'buoyport': true,
    'wsum': true,
    'ssum': true,
    'bsum': true,
    'calc': false,
    'climad': true,
    'buoyd': true,
    'syncdata': true
  }, 'seen': {
    'climaport': 1666614754,
    'clima': 1666639353,
    'buoyport': 1666614753,
    'wsum': 1666643402,
    'ssum': 1666645980,
    'bsum': 1666645980,
    'calc': 0,
    'climad': 1666614754,
    'buoyd': 1666614753,
    'syncdata': 1666614751
  }, 'indeks': [false, false, false]
}
let messure = {
  'measure': {
    'climatime': 1666642821, 'ws': 57, 'we': -70, 'tp': 129, 'rh': 960, 'ap': 10093, 'tpr': 122, 'wv': 90, 'wd': 2310
  }
}
*/
