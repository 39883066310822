const serverUri = "https://api.bathymetr.com/v1/geodata";

const enviroment = "development";

export const configuration = {
    serverUri,
    enviroment, // development | test | production
    map: {
        center: [18.3373787, 54.7228956],
        zoom: 11,
    },
    peninsulaMap: {
        center: [18.6373787, 54.7028956],
        zoom: 11.3,
    },
};