import { TableCell } from '@mui/material'
import React from 'react'
import { getWindSpeed, getWindAngle, getWindView } from '../../utils/wind'

/**
 * ADCPVTableCell Component
 * @constructor
 * This component renders a table cell with the 'v' metric and 'adcp' combined sensor name  
 * @param {array} data array containing data for the table cell.
 * Each element of the array is an object containing the data for each table cell.
 * Keys of the object are the [combinedSensorName] and the timestamp.
 * The value of the [combinedSensorName] key is an object with available metrics.
 */
const ADCPVTableCell = ({data}) => {

  /** Convert the data to the format required by the graph. */
  const _data = []

  return <TableCell align="right">
    {
      /** Get the wind speed and angle for each depth. */
      data?.map((item, index) => {
        const value = []
        const angle = []
        /** If the metric index is not 0, 7, 14, 22, skip it. */
        if(![0, 7, 14, 22].includes(index)) 
          return null
        value.push(getWindSpeed({we: item[0], ws: item[1]}))
        angle.push(getWindAngle({we: item[0], ws: item[1]}))
        _data.push({
          timestamp: item.timestamp,
          value,
          angle
        })
        /** render the current speed and angle for each depth. */
        return <div key={index}>
          {value.map((item, index) => {
            return (
              <div>
                {item.toFixed(2) + ' mm/s'} {getWindView(angle[index])}
              </div>
            )
          })}
        </div>
      })
    }
  </TableCell> 
}

export default ADCPVTableCell