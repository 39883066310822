import GeoJSON from 'ol/format/GeoJSON'

/**
 * Load geojson file. It returns a promise that resolves to a GeoJSON object containing loaded features.
 * @param {string} filename - name of the file to load
 */
export const loadGeojson = (filename) => {
  return fetch(filename).then(r => {
    return r.json().then((j => {
      const gj = new GeoJSON({
        // featureProjection: 'EPSG:3857',
        // dataProjection: 'EPSG:3857',
        // dataProjection: 'EPSG:2180',
      }).readFeatures(j)
      // console.log('json', j, gj)
      return gj
    }))
  })
}