import React, { useContext } from 'react'
import { AppContext } from '../../App'

/**
 * AuxTimeLegend Component
 * @constructor
 * This component renders an additional sections of the forecast time legend.
 * It shows the days of the forecast.
 */
const AuxTimeLegend = () => {

  /** forecasts contains data about forecasts. */
  const {forecasts} = useContext(AppContext)

  let days = [] // as {day: string, quantity: number}[] = []

  /** This loop iterates over the forecasts and creates an array of days. 
   * Each day is an object with the following properties:
   * day: string, quantity: number
   * The quantity property is the number of forecasts for that day.
   * The day property is the day of the forecast.
   */
  forecasts.forEach(f => {
    const index = days.findIndex(d => d.day === f.day)
    if(index === -1) {
      days.push({day: f.day, quantity: 1})
    } else {
      days[index].quantity++
    }
  })

  return (
    <div className='auxTimeLegend'>
      {/* The width of each day is proportional to the number of forecasts for that day.
        * The width is calculated by multiplying the number of forecasts by 70.
        * The width is used to set the width of the div that contains the day.
      */}
      {days.map((d) => (
        <div 
          key={d.day} 
          style={{width: (d.quantity * 70)+'px'}} 
          className='auxTimeLegendChip'
        >
          {d.day}
        </div>
      ))}
    </div>
  )
}

export default AuxTimeLegend