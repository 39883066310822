
/**
 * TextLayer component
 * @constructor
 * This component renders a layer on the map which displays text warning.
 */
const TextLayer = ({layerToShow}) => {

  return(
    <div className='test-warning'>
      WYKORZYSTANE DANE POMIAROWE RZECZYWISTE, MODELE NUMERYCZNE - ETAP TESTÓW.
    </div>
  )

}

export default TextLayer