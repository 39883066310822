/** Correct magnittudes are calculated up to 31. Magnitudes greater than 31 are considered to be incorrect. */
const maxCorrectMagnitude = 31;

/**
 * getEquidistantItems Function
 * This function aims to reduce the number of elements in an array representing coastal currents.
 * It does so by evaluating the range of x-values within the input data and selecting
 * a predetermined number of elements, which are equidistant and whose magnitude field
 * is within a certain range.
 */
function getEquidistantItems({inputArray, numOutputElements, magnitudeField}) {
  /** Define the x-coordinate minimum and maximum values from the input array. */
  const minX = inputArray[0].x;
  const maxX = inputArray[inputArray.length - 1].x;

  const range = maxX - minX;

  const step = range / (numOutputElements - 1);

  /** Initialize the output array to store the selected items. */
  const outputArray = [];

  /** Initialize targetX, starting with the minimum x value. */
  let targetX = minX;

  /** Iterate through the input array. */
  for (let i = 0; i < inputArray.length; i++) {
    /** If the x-value of the current item is greater than or equal to the target x-value, 
     * and the magnitude is less than or equal to the maximum correct magnitude,
     * add the item to the output array and increment the target x-value by the step size.
     * */
    if (inputArray[i].x >= targetX && Math.abs(inputArray[i][magnitudeField]) <= maxCorrectMagnitude) {
      outputArray.push(inputArray[i]);
      targetX += step;
    }

    /** If the output array has the desired number of elements, break out of the loop. */
    if (outputArray.length === numOutputElements) {
      break;
    }
  }

  /** Return the array of selected items. */
  return outputArray;
}

/**
 * getReducedCoastalCurrents Function
 * This function aims to reduce the number of elements in an array representing coastal currents.
 * It does so by evaluating the range of x-values within the input data and selecting 
 * a predetermined number of elements, which are equidistant and whose magnitude field
 * is within a certain range.
 * @param {Array} inputArray - The array of coastal currents data to be reduced.
 * @param {String} magnitudeField - The name of the field in the input array which contains the magnitude values.
 * @returns {Array} The array of coastal currents data with a reduced number of elements.
 */
export const getReducedCoastalCurrents = (inputArray, magnitudeField) => {
        
    /** Define the x-coordinate minimum and maximum values from the input array. */
    const maxX = inputArray[inputArray.length - 1].x;
    const minX = inputArray[0].x;
    let numOutputElements;

    /** Determine the number of output elements based on the range of x-values. */
    if (maxX - minX <= 300) {
      numOutputElements = 3;
    } else if (maxX - minX <= 600) {
      numOutputElements = 4;
    } else if (maxX - minX <= 900) {
      numOutputElements = 5;
    } else if (maxX - minX <= 1200) {
      numOutputElements = 6;
    } else if (maxX - minX <= 1500) {
      numOutputElements = 7;
    } else {
      numOutputElements = 8;
    }

    /** Get the equidistant items from the input array. */
    const outputArray = getEquidistantItems({inputArray, numOutputElements, magnitudeField});

    /** Return the array of selected items. */
    return outputArray;
}