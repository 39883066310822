import { RLayerVector } from 'rlayers'
import GeoJSON from 'ol/format/GeoJSON'
import baltic from '../../../data/baltic.geojson'
import { fromLonLat, get } from 'ol/proj'
import { RFill, RStroke, RStyle } from 'rlayers/style'
import { useEffect, useState } from 'react'

/** BalticLayer is a layer with the Baltic sea polygon */
export const BalticLayer = ({ zIndex = 12 }) => {

  const [features, setFeatures] = useState(null)

  useEffect(() => {
    fetch(baltic).then(r => r.json().then(j => {
      setFeatures(new GeoJSON().readFeatures(
          j, {
            featureProjection: get('EPSG:3857'),
          }
        )
      )
    }))
  }, [])

  return features && <RLayerVector
    features={features}
    zIndex={zIndex}>
    <RStyle>
      <RStroke color={'grey'} width={0.5}/>
      <RFill color={'rgba(86,202,227,0.5)'}/>
    </RStyle>
  </RLayerVector>
}