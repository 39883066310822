import { configuration } from "../config"

/** 
 * This function fetches erosion data for a given forecast.
 * @param {string} forecast
 */
export const fetchErosionData = (forecast) => {
  return fetch(`${configuration.serverUri}/coastal_erosion/${forecast}`)
    .then(async res => {
      try {
        const rawResponseText = await res.text();
        const data = rawResponseText.replace(/Infinity/g, 0).replace(/NaN/g, 0);  
        const _data = JSON.parse(data);
        return _data;
      } catch (e) {
        console.log('fetchCoastalCurrents err', e);
      }
    })
}
