import React from 'react'
import { DataVisualisationChildItem } from './DataVisualisationChildItem';
import { filterDataForWrongParams, findBuoy, getMarkerConfig, getSensorsWithMetricNames } from '../graph/utils'
import { useQuery } from 'react-query';
import { format, getUnixTime, parseISO } from 'date-fns'
import { fetchData } from '../graph/utils';
import { markerTypes } from '../../utils/buoyConnect'

/**
 * DataVisualisationItem Component
 * @constructor
 * This component renders sensor metrics for a marker.
 * @param {object} item - sensor's data.
 * @param {object} data - marker's configuration data.
 */
const DataVisualisationItem = ({item, data}) => {

  /** Find the buoy and its group based on the marker's ID. */
  const {buoy, buoyGroup} = findBuoy(data.id) 

  /** Get the available sensor metrics names for the buoy. */
  const sensorsWithMetricNames = getSensorsWithMetricNames(buoy)
  const markerConfig = getMarkerConfig(buoy)

  /** Get the helapi(id) for the buoy group. */
  const helapi = buoyGroup?.helapi

  const endDate = format(new Date(), "yyyy-MM-dd'T'HH:mm")

  /** Get the start date for the data. It is 7 days before the current date. */
  const startDate =
    format(new Date(
      new Date().setDate(new Date().getDate() - 7)), "yyyy-MM-dd'T'HH:mm"
    );

  /** State to store the loaded data for the graph. */
  const [_graphData, set_graphData] = React.useState(null)

  /** Fetch data from the server. 
   * It is fetched when the helapi(group buoy id), startDate, endDate.
  */
  const { isLoading, isError } = useQuery([helapi, startDate, endDate, sensorsWithMetricNames], fetchData, {
    onSuccess: (data) => {
      data = filterDataForWrongParams(data, markerConfig).map(
        item=> ({...item, unixTimestamp: getUnixTime(parseISO(item.timestamp))})
      )

      set_graphData(data)
      /** If the sensor is 'clima', calculate the wind speed and angle. */
      if(item.name === 'clima') {
        setTimeout(() => {
          calcWindParams(data)
        }, 0)
      }
    },
    retry: false,
  });
  /** Function to calculate the wind speed and angle. */
  const calcWindParams = (data) => {
    const _data = []
    data.forEach((item) => {
      const we = Number(item.clima.we);
      const ws = Number(item.clima.ws);
      item.clima.wv = Math.sqrt(we * we + ws * ws);
      item.clima.wd = 180 + Math.atan2(-we, ws) * 180 / Math.PI;
      _data.push(item)
    })
    set_graphData(_data)
  }


  return (
    <>
        {item.children.map((child) => {
            /** Render a DataVisualisationChildItem component for each metric. */
            return <DataVisualisationChildItem 
              child={child} 
              item={item} 
              key={child.key} 
              graphData={_graphData}
              isGraphError={isError}
              isGraphLoading={isLoading}
            />;
        })}
    </>
  )
}

export default DataVisualisationItem