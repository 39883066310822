import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/**
 * LightTooltip Component
 * @constructor
 * This functional component create a custom styled Tooltip component with a light theme.
 */
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 95,
  },
}));

/**
 * BuoyTooltip Component
 * @constructor
 * Create a custom BuoyTooltip component that wraps content with a styled Tooltip
 * @param {string} title - The text to be displayed in the tooltip
 * @param {ReactNode} children - The content to be wrapped by the tooltip
 */
export const BuoyTooltip = ({ title, children }) => {
  /** Return a custom styled Tooltip component with a light theme */
  return (
    <LightTooltip
      title={title}
      PopperProps={{
        placement: "right",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -0],
            },
          },
        ],
      }}
    >
      {children}
    </LightTooltip>
  );
};
