import { Grid, Tooltip } from "@mui/material";
import { dataTypes, markerTypes } from "../../utils/buoyConnect";
import React, { useCallback, useEffect, useState } from "react";
import DataVisualisationItem from "./DataVisualisationItem";
import StatisticHistory from "./StatisticHistory";

/**
 * DataVisualization Component
 * @constructor
 * This component renders a data visualization for a marker.
 * It displays the metrics from the marker's sensors.
 * @param {object} data - marker's configuration data.
 * @param {object} popupRef - refence to the marker's popup.
 */
export const DataVisualization = ({ data, popupRef }) => {
  /** State to store the loaded and converted for display data. */
  const [dataLoad, setDataLoad] = useState({sensors: []});
  /** Get the marker's type. */
  const type = data.type;
  /** Get metadata for the marker type. */
  const objectMeta = markerTypes[type];

  /** Function to update the dataLoad state with the data fetched from the server and converted for display. */
  const updateData = useCallback(() => {
    /** Fetch metrics from the marker's sensors */
    fetch(data.measureUrl)
      .then((result) =>
        result.json().then((measure) => {
          /** Create an array of sensor metrics. */
          let filteredData = Object.keys(
            objectMeta ? objectMeta.filter : {}
          ).map((sensor) => {
            const ret = {
              name: sensor,
              /** Create an array of metrics for each sensor. */
              children: Object.keys(objectMeta.filter[sensor]).map((metric) => {
                const fieldMeta = dataTypes[objectMeta.filter[sensor][metric]];
                const value = measure[sensor] && measure[sensor][metric];
                return {
                  fieldMeta,
                  value,
                  key: metric,
                  formatted: fieldMeta?.format(value, measure),
                  tooltip: typeof fieldMeta.tooltip == 'function'? fieldMeta.tooltip(value, measure): fieldMeta.tooltip,
                };
              }),
            };
            return ret;
          });
          let sensorStatus = false, message = ''
          if (objectMeta?.wrongDataAlert) {
            ({ sensorStatus, message } = objectMeta.wrongDataAlert(data))
          }

          setDataLoad({
            sensorStatus,
            message,
            sensors: filteredData
          });
        })
      )
      .catch((e) => console.error(e));
  }, [data, objectMeta]);

  /** Effect hook to update data from the server when the popup is visible. */
  useEffect(() => {
    if(!popupRef?.current?.visible)
      return;
    updateData();
    // TODO add more intelligent reloading
    const timer = setInterval(updateData, 10000);
    return () => {
      clearInterval(timer);
    };
  }, [data.measureUrl, updateData, popupRef?.current?.visible]);

  /** Effect hook to move the 'thmax' value after the 'dirp' value. */
  // useEffect(() => {
  //   if (dataLoad.length > 0) {
  //     const $thmax = document.querySelector(".thmax");
  //     if($thmax){
  //       const $dirp = document.querySelector(".dirp");
  //       $dirp.insertAdjacentElement("afterend", $thmax);
  //     }
  //   }
  // }, [dataLoad]);
  //
  if (data.notInstalled) {
    return (
      <Grid container justifyContent={"center"}>
        <Grid item>Przerwa serwisowa</Grid>
      </Grid>
    );
  }
  // handle bad data message
  if (dataLoad?.sensorStatus){
    return <Grid container justifyContent={"center"}>
      <Grid item>{dataLoad.message}</Grid>
    </Grid>
  }

  return (
    <Grid container>
      {dataLoad.sensors.length === 0 && <>Loading...</>}
      {dataLoad.sensors.map((sensor) => {
        return (
          <React.Fragment key={sensor.name}>
            <Grid item md={12}>
              {/*item.name*/}
            </Grid>
            <DataVisualisationItem item={sensor} data={data}/>
          </React.Fragment>
        );
      })}
      <StatisticHistory data={data} />
    </Grid>
  );
};
