import { Point } from "ol/geom";
import { fromLonLat } from "ol/proj";

const distanceUnits = {
  km: 1000,
  m: 1,
  mm: 0.001,
};

/** helper function to get meters from string like "1 km" or "1 m" */
const getMetersFromScaleString = (string) => {
  const coeff = string.split(" ")[0];
  const unit = string.split(" ")[1];
  return coeff * distanceUnits[unit];
};

/** helper function to get marker radius in meters
 * @param {string} renderedHTML - title of the rendered scale
 * @param {number} renderedWidth - pixel width of the rendered scale
*/
export const getRadiusInMeter = (renderedHTML, renderedWidth) => {
  const meters = getMetersFromScaleString(renderedHTML);
  const markerRadiusInPixels = 15;
  return (meters * markerRadiusInPixels) / renderedWidth;
};

/** Helper function to convert longitude and latitude values to meters */ 
function lonLatToMeters(lon, lat) {
  const R = 6371000; // earth radius in meters
  const phi = (lat * Math.PI) / 180;
  const x = R * Math.cos(phi) * Math.cos((lon * Math.PI) / 180);
  const y = R * Math.cos(phi) * Math.sin((lon * Math.PI) / 180);
  const z = R * Math.sin(phi);
  return { x, y, z };
}

/** Helper function to calculate the distance between two points in meters */ 
function distanceInMeters(point1, point2) {
  const dx = point1.x - point2.x;
  const dy = point1.y - point2.y;
  const dz = point1.z - point2.z;
  return Math.sqrt(dx * dx + dy * dy + dz * dz);
}

/** Helper function to check for circle intersections */ 
function checkIntersection(circle1, circle2, radius, isMainValues) {
  const point1 = lonLatToMeters(
    isMainValues ? circle1.lon : circle1._lon,
    isMainValues ? circle1.lat : circle1._lat
  );
  const point2 = lonLatToMeters(
    isMainValues ? circle2.lon : circle2._lon,
    isMainValues ? circle2.lat : circle2._lat
  );
  const distance = distanceInMeters(point1, point2);
  return distance < 2 * radius;
}

export const adjustCoordinates = ({ buoys, radius }) => {
  /** set a maximum number of iterations */
  let maxIterations = 50;
  let counter = 0;
  let hasIntersection = true;
  while (hasIntersection && counter < maxIterations) {
    hasIntersection = false;
    for (let i = 0; i < buoys.length; i++) {
      for (let j = i + 1; j < buoys.length; j++) {
        const isMainValues = counter === 0;
        if (
          i !== j &&
          checkIntersection(buoys[i], buoys[j], radius, isMainValues)
        ) {
          //   console.log("check intersection", i, j, { counter });
          hasIntersection = true;

          const adjustXValue = 0.001;
          const adjustYValue = 0.00025;
          const adjustXValueCoeff = buoys[i].lon > buoys[j].lon ? 1 : -1;
          const adjustYValueCoeff = buoys[i].lat > buoys[j].lat ? 1 : -1;

          const _ilon = counter === 0 ? buoys[i].lon : buoys[i]._lon;
          const _ilat = counter === 0 ? buoys[i].lat : buoys[i]._lat;
          const _jlon = counter === 0 ? buoys[j].lon : buoys[j]._lon;
          const _jlat = counter === 0 ? buoys[j].lat : buoys[j]._lat;

          buoys[i]._lon = _ilon + adjustXValueCoeff * adjustXValue;
          buoys[i]._lat = _ilat + adjustYValueCoeff * adjustYValue;
          buoys[j]._lon = _jlon - adjustXValueCoeff * adjustXValue;
          buoys[j]._lat = _jlat - adjustYValueCoeff * adjustYValue;

          buoys[i].geometry = new Point(
            fromLonLat([buoys[i]._lon, buoys[i]._lat])
          );
          buoys[j].geometry = new Point(
            fromLonLat([buoys[j]._lon, buoys[j]._lat])
          );
        }
      }
    }
    counter++;
  }
  return buoys;
};
