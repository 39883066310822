import { buoysConfig, dataTypes, markerTypes } from '../../utils/buoyConnect';
import { getWindAngle, getWindSpeed } from '../../utils/wind';
import { fromUnixTime, getUnixTime, parseISO } from 'date-fns'

/**
 * Function to fetch data from the server.  
 * @param {object} queryKey - queryKey is an array containing the parameters for the query.
 * It contains the following parameters:
 * @param {string} queryKey[0] - The buoy group helapi(id)
 * @param {string} queryKey[1] - The start date of the data to fetch.
 * @param {string} queryKey[2] - The end date of the data to fetch.
 * @param {object} queryKey[3] - The measures to fetch.
 */
export const fetchData = async ({ queryKey }) => {
  const [helapi, startDate, endDate, measures] = queryKey;
  const response = await fetch(`https://api.bathymetr.com/v1/measure/${helapi}/range`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "start_date": startDate,
      "end_date": endDate,
      measures
    }),
  });
  const data = await response.json();

  // if(helapi === 'hel-pu') {
  //   return helpuData.data
  // }

  return data.data;
};

/**
 * filter data so it only contains data not marked as Wrong data
 * @param {array} data array of measures
 * @param {object} markerConfig from bouoyConnect.markerType
 * @returns {*}
 */
export const filterDataForWrongParams = (data, markerConfig) => {
  let ranges
  let ret = data
  if (data && typeof markerConfig?.dataWrongRanges === 'function') {
    ret = data.sort((a, b) => a.timestamp - b.timestamp)
    ranges = markerConfig.dataWrongRanges(data)
    ret = ret.map((item => {

      for (let range of ranges) {
        if (item.timestamp >= range.start && (range.end === null || item.timestamp < range.end)) {
          for (let key in item.wsum) {
            item.wsum[key] = null
          }
          for (let key in item.ssum) {
            item.ssum[key] = null
          }
          return {
            ...item,
            wrongData: true,
          }
        }
      }
      return item
    }))
  }
  return ret
}

/**
 * Function to transform data to a format that can be displayed in a table.
 * @param {object} data The data to transform.
 * @param {object} measures sensors with metrics names.
 * @returns {object} An object containing the transformed data in a format that can be displayed in a table.
 * The object contains the following parameters:
 * @param {array} combinedDataArr array containing the transformed data.
 * Each element of the array is an object containing the data for each row.
 * Keys of the object are the [combinedSensorName] and the timestamp.
 * The value of the [combinedSensorName] key is an object with available metrics.
 * @param {array} combinedDataParams array is used to create the table columns. 
 * It contains keys for each metric from the [combinedSensorName] property of the combinedDataArr array.
 * @param {string} combinedSensorName A string containing the combined sensor name.
 */
export const transformDataToTable = (data, measures,markerConfig) => {
  /** Get the sensors names. */
  const sensorNames = Object.keys(measures);
  /** Get the combined sensor name. */
  const combinedSensorName = sensorNames.join('');

  /** The combinedData object is used to create the table rows. 
   * It contains the data for each row.
   * The key of the object is the timestamp.
   * The value of the object is an object containing the data for each row.
  */
  let combinedData = {};

  /** The combinedDataParams array is used to create the table columns. */
  const combinedDataParams = []
  data = filterDataForWrongParams(data, markerConfig)

  data?.forEach(entry => {
    const timestamp = entry.timestamp;
    const unixTimestamp = getUnixTime(parseISO(entry.timestamp))
    const wrongData = entry.wrongData;

    if(!combinedData[timestamp]) combinedData[timestamp] = {};
    if(!combinedData[timestamp][combinedSensorName]) combinedData[timestamp][combinedSensorName] = {};
    
    sensorNames.forEach(sensorName => {
      const metricNames = measures[sensorName]

      metricNames.forEach(metricName => {
        /** paramName is a unique name for each metric. It is wrong to use the metricName as a key because 
         * the same metricName can be used for different sensors.
         * This paramName is used as a key in the combinedData object.
         * It is also used to create the combinedDataParams array.
         */
        const paramName = sensorNames.length > 1 ? `${metricName}(${sensorName})` : metricName;

        /** If the paramName is not in the combinedDataParams array, add it. */
        if(!combinedDataParams.includes(paramName)) combinedDataParams.push(paramName);

        /** If the paramName is not in the combinedData object, add it. */
        if(!combinedData[timestamp][combinedSensorName][paramName]) {
          /** 
           * If the metric name is 'wv' or 'wd', calculate it from the 'we' and 'ws' metric names.
           * Otherwise, get the metric name from the data.
           */
          combinedData[timestamp][combinedSensorName][paramName] = 
            metricName === 'wv'? getWindSpeed({we: entry[sensorName]?.['we'], ws: entry[sensorName]?.['ws']}) :
            metricName === 'wd'? getWindAngle({we: entry[sensorName]?.['we'], ws: entry[sensorName]?.['ws']}) :
          entry?.[sensorName]?.[metricName];
        }
      })
      combinedData[timestamp]['timestamp'] = timestamp;
      combinedData[timestamp]['wrongData'] = wrongData;
      combinedData[timestamp]['unixTimestamp'] = unixTimestamp;
    });
  });

  const combinedDataArr = Object.values(combinedData);
  return { combinedDataArr, combinedDataParams, combinedSensorName };
}

/**
 * Find a buoy's configuration and its group based on the marker's ID.
 * @param {string} buoyId The ID of the buoy to find.
 * @returns {Object} An object containing the buoy and its group.
 */
export const findBuoy = (buoyId) => {
  let buoy = null
  /** Find the buoy and its group based on the marker's ID in the buoysConfig array. 
   * Buoy is located in the points array of the buoyGroup.
  */
  const buoyGroup = buoysConfig.find(buoyGroup => {
    const pointsConfig = buoyGroup.points
    buoy = pointsConfig.find(buoy => buoy.id === buoyId)
    if (buoy) {
      return true
    }
  })
  return {buoyGroup, buoy}
}

/**
 * Get an object containing the marker config
 * @param {object} buoy - The buoy configuration to get the marker config for.
 */
export const getMarkerConfig = (buoy) => {
  /** Get the marker config. */
  return Object.values(markerTypes).find(marker => marker.id === buoy?.type)
}

/**
 * Get an object containing the metrics names for each sensor.
 * @param {object} buoy - The buoy configuration to get the sensor metrics for.
 * @param {'popup'|'table'} filterType
 */
export const getSensorsWithMetricNames = (buoy, filterType= 'popup') => {
  const marker = Object.values(markerTypes).find(marker => marker.id === buoy?.type);
  /** Get the sensor metrics for the buoy. */
  let sensors = marker?.filter
  if (filterType === 'table' && marker?.dataFilter){
    sensors = marker.dataFilter;
  }

  /** Get the sensors names. */
  const sensorNames = Object.keys(sensors || {})

  /** Create an object to store the metrics */
  let sensorsWithMetricNames = {}

  /** Create an object containing the metrics names for each sensor. */
  sensorNames.forEach(sensorName => {
    sensorsWithMetricNames[sensorName] = Object.keys(sensors[sensorName])
  })

  return sensorsWithMetricNames
}

/**
 * Get an object containing the metrics units for each metric.
 * @param {object} buoy - The buoy configuration to get the metrics units for.
 * @param {'popup'|'table'} filterType
 */
export const getMetricsWithUnits = (buoy, filterType= 'popup') => {
  const marker = Object.values(markerTypes).find(marker => marker.id === buoy?.type);
  /** Get the sensor metrics for the buoy. */
  let sensors = marker?.filter
  if (filterType === 'table' && marker?.dataFilter){
    sensors = marker.dataFilter;
  }
  /** Get the sensors names. */
  const sensorNames = Object.keys(sensors || {})

  /** Create an object to store the metrics */
  let metrics = {}
  sensorNames.forEach(sensorName => {
    metrics = {...metrics, ...sensors[sensorName]}
  })

  const metricNames = Object.keys(metrics)

  /** Create an object to store metrics units. */
  let metricsWithUnits = {}
  metricNames.forEach(metricName => {
    metricsWithUnits[metricName] = dataTypes[metrics[metricName]]?.units || ''
  })

  return metricsWithUnits
}

/**
 * Get an object containing the metrics symbols for each metric.
 * @param {object} buoy - The buoy configuration to get the metrics symbols for.
 */
export const getMetricsWithSymbols = (buoy) => {
  /** Get the sensor metrics for the buoy. */
  let sensors = Object.values(markerTypes).find(marker => marker.id === buoy?.type)?.filter

  /** Get the sensors names. */
  const sensorNames = Object.keys(sensors || {})

  /** Create an object to store the metrics */
  let metrics = {}
  sensorNames.forEach(sensorName => {
    metrics = {...metrics, ...sensors[sensorName]}
  })

  const metricNames = Object.keys(metrics)

  /** Create an object to store metrics symbols. */
  let metricsWithSymbols = {}
  metricNames.forEach(metricName => {

    metricsWithSymbols[metricName] = {
      symbol: dataTypes[metrics[metricName]]?.symbol || '',
      tooltip: dataTypes[metrics[metricName]]?.tooltip || '',
    }
  })
  console.log('metricsWithSymbols', metricsWithSymbols, metricNames)
  return metricsWithSymbols
}


  
