import React from 'react'

/**
 * StatisticHistory Component
 * @constructor
 * This component renders a button to display the history of the marker's metrics.
 * @param {object} data - marker's configuration data.
 */
const StatisticHistory = ({data}) => {

  const openNewWindow = () => {
    console.log({data});
    const url = `/${data.id}`
    const width = 1000;
    const height = 500;
    const left = (window.innerWidth - width) / 1.8;
    const top = 100;

    window.open(
      url,
      '_blank',
      `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no,width=${width},height=${height},top=${top},left=${left}`
    );
  };

  return (
    <div className="data-visualisation-stats-btn" onClick={openNewWindow}>historia pomiarów</div>
  )
}

export default StatisticHistory