import { useContext, useEffect, useRef, useState } from 'react'
import { getColorsBetween } from '../../utils/getColorsBetween'
import { AppContext } from '../../App'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import { useQueryClient, useIsFetching, useIsMutating } from 'react-query'
import AuxTimeLegend from './auxTimeLegend'

const colorsDefault = ['#19e4ff', '#009dff', '#006fff', '#003cff', '#051bb0',]

/** Component TimeLegend provides a UI feature that shows a legend of forecast times. */
export const TimeLegend = () => {

  /** Local state for maintaining playback status (playing or stopped) */
  const [playing, setPlaying] = useState(true)

  const {
    currentForecast, 
    setCurrentForecast, 
    setNextForecast,
    forecasts,
  } = useContext(AppContext)

  /** Function to handle a click on a forecast. It checks if the forecast is disabled,
   * clears any existing timers, sets playing state to false, and updates curentForecast and nextForecast.
   */
  const clickForecast = (forecast, isDisabled) => {

    if(isDisabled) return

    clearTimeout(timerId);
    setPlaying(false);
    setCurrentForecast(forecast)

    /** Find the index of the clicked forecast and set the next forecast */
    const forecastIdx = forecasts.findIndex(f => f.id === forecast);
    if (forecastIdx !== forecasts.length - 1) {
      setTimeout(() => {
        setNextForecast(forecasts[forecastIdx + 1].id);
      }, 1000);
    }
  }

  /** Instance of queryClient from 'react-query' to interact with query cache */
  const queryClient = useQueryClient();

  /** Generate a range of colors between the first and last color in 'colorsDefault', equal in length to 'forecasts'. */
  const colors = getColorsBetween(colorsDefault[0], colorsDefault[colorsDefault.length - 1], forecasts.length)

  /** State variable to store the id of the set timer */
  const [timerId, setTimerId] = useState(null);

  const currentForecastIdx = forecasts.findIndex(f => f.id === currentForecast);

  /** Boolean representing if we are at the end of the forecasts */
  const isEnd = currentForecastIdx === forecasts.length - 1 || forecasts[currentForecastIdx + 1]?.isDisabled

  /** Function to change the forecast to the next one in the 'forecasts' array */
  const changeForecast = () => {
    /** If we are at the end of the forecasts, stop playing */
    if (isEnd) {
      setPlaying(false);
      return;
    }
    /** Set the next forecast and possibly the one after it */
    if (currentForecastIdx !== forecasts.length - 1 && currentForecastIdx !== forecasts.length - 2) {
      setTimeout(() => {
        setNextForecast(forecasts[currentForecastIdx + 2].id);
      }, 1000);
    }
    setCurrentForecast(forecasts[currentForecastIdx + 1].id);
  };

  /** Function to start playing the forecasts */
  const start = () => {
    setPlaying(true);
    /** If we are at the end of the forecasts, set the current forecast to the first one */
    if(isEnd || playing){
      setCurrentForecast(forecasts[0].id);
    }
  };

  /** State variables to check if queries are fetching or mutating data using 'react-query' hooks */
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  /** useEffect to log the cached queries when no queries are fetching or mutating */
  // useEffect(() => {
  //   if (isFetching === 0 && isMutating === 0) {
  //     const cached = queryClient.getQueryCache()
  //     console.log({cached})
  //   }
  // }, [isFetching, isMutating]);

  /** useEffect to handle the automatic change of forecasts when playing */
  useEffect(() => {
    /** If we are not playing, clear the timer and return */
    if (!playing) {
      clearTimeout(timerId);
      return;
    } 
    if(currentForecastIdx === 0){
      clearTimeout(timerId);
    }

    /** If we are not fetching or mutating data, set a timer to change the forecast */
    if(isFetching === 0 && isMutating === 0){
      const timeout = setTimeout(() => {
        changeForecast();
      }, isEnd? 0: 2000);
      setTimerId(timeout);
      return () => clearTimeout(timeout);
    }
  }, [playing, currentForecast, isFetching, isMutating]);

  if (!forecasts) return <></>

  return <div className="timeArrowLegend" 
    // style={{left: legendLeft}}
  >
    {forecasts.length !== 0 && <><div>
      prognozy parametrów:
    </div>
    <div className='main-row'>
      {forecasts?.length > 1 && <div className='btn-wrapper' onClick={start}>
        {/* Toggle between play and replay icons based on 'playing' state */}
        {playing? <ReplayCircleFilledIcon fontSize='large'/>: <PlayCircleFilledIcon fontSize='large'/>}
      </div>}
      <div className='legendBars'>
        <div className='legendContainer'>
          {forecasts.map((forecast, index) => {
            /** Add the current class to the current forecast */
            const current = forecast.id === currentForecast ? 'current' : ''
            /** Check the cache for the data for this forecast */
            const cached = queryClient.getQueryData(['forecastData', forecast])
            return <div 
              className={`legendChip ${current} ${forecast.isDisabled? 'disabled': ''}`} 
              key={forecast.id} onClick={()=>clickForecast(forecast.id, forecast.isDisabled)}
            >
              {forecast.value}
            </div>
          })}
        </div>
        <AuxTimeLegend />
      </div>
    </div></>}
  </div>
}