import React from 'react'
import MenuItem from './menuItem'

/**
 * CheckboxList Component
 * @constructor
 * This component renders a list of menu items.
 * @param {array} arr - array of menu items.
 * @param {object} configuration - redux state.
 * @param {object} dispatch - redux dispatch function.
 * @param {object} mapRef - reference to the map.
 */
export const CheckboxList = ({ arr, dispatch, configuration, mapRef }) => {

  return (
    <>
      {arr.map(item => {
        return <MenuItem item={item} key={item.label}
          configuration={configuration} dispatch={dispatch} mapRef={mapRef}
        />
      })}
    </>
  )
}