import React from 'react';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';

/**
 * ErosionChart Component
 * @constructor
 * This component renders a chart with erosion data.
 * @param {array} data array containing data for the chart.
 * Each element of the array is an object containing the data for each chart.
 * Keys of the object are x - distance from the shore, 
 * b - depth of the bottom, s - height of the free water surface, h - wave height. 
 */ 
export const ErosionChart = React.memo(({ data }) => {

  /** Sort the data by the x value. */
  const sortedData = data?.sort((a, b) => a.x - b.x);

  console.log({sortedData});
  return (
    <div className='erosion'>
      <LineChart width={800} height={400} data={sortedData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="x" 
          label={{ value: "m", position: "insideBottom", dy: 5, dx: 340 }}
        />
        <YAxis 
          label={{ value: "m", position: 'insideTop', dy: 10, dx: -5 }}
        />
        <Tooltip 
          formatter={(value, name, item, index, payload) => {
            return `${value} m`;
          }}  
          labelFormatter={(value) => {
            return `x: ${value} m`;
          }}
        />
        {/* <Legend /> */}
        <Line type="monotone" dataKey="b" label="brazowa linia glebokosc dna" stroke="brown" dot={null}/>
        <Line type="monotone" dataKey="s" label="jasno niebieska - wysokosc powierzchni swobodnej wody" stroke="#087EA6" dot={null}/>
        <Line type="monotone" dataKey="h" label="ciemno niebieska - wysokość fali średniokwadratowej (Hrms)" stroke="navy" dot={null}/>
      </LineChart>
      <div className='erosion-legend'>
        <p className='erosion-b'>b - glebokosc dna</p>
        <p className='erosion-s'>s - wysokosc powierzchni swobodnej wody</p>
        <p className='erosion-h'>h - wysokość fali średniokwadratowej (Hrms)</p>
      </div>
    </div>
  );

});