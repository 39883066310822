/**
 * CameraVisualization component.
 * @constructor
 * This component renders a layer on the map which displays a camera stream or image if there is no stream.
 */
export const CameraVisualization = ({ data }) => {
  /** Check if there is no stream URL, display an image instead. */
  if(!data.streamUrl)
    return (
      <div>
        <img src={data.imageUrl} style={{ maxWidth: 400 }}/>
        <a href={data.imageUrl} target={'_blank'}>Pełny ekran</a>
      </div>
    )

  /** Otherwise, display the stream. */
  return <div>
    <div className='embed-container'>
      <iframe 
        src={data.streamUrl}
        style={{border: 0}} 
        allow="autoplay; fullscreen"
        allowFullScreen={true}
      />
    </div>
    {/* Provide a link to view the video in fullscreen mode. */}
    <a href={data.streamUrl} target={'_blank'}>Pełny ekran</a>
  </div>
}