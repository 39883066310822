import { useQuery } from "react-query";
import { fetchData, transformDataToTable } from "./utils";
import { DataTable } from "./data-table";
import { Typography, Button } from '@mui/material';
import Papa from 'papaparse';

/**
 * FullTable Component
 * @constructor
 * This component renders a table with data for a marker.
 * It displays the metrics from the marker's sensors for a given time period with a button to download the data as CSV.
 * @param {string} startDate - start date for the data.
 * @param {string} endDate - end date for the data.
 * @param {string[]} measures - sensors with metrics names.
 * @param {string} helapi - helapi(id) for the group.
 * @param {object} units - metrics with units.
 * @param {object} symbols - metrics with symbols.
 */
export const FullTable = ({ startDate, endDate, measures, helapi, units, symbols, markerConfig}) => {

  /** Fetch data from the server.
   * It is fetched when the helapi(group buoy id), startDate, endDate are changed. */
  const { data, isLoading, isError } = useQuery([helapi, startDate, endDate, measures], fetchData, {retry: false});

  /** Transform the data to a format that can be displayed in a table. 
   * The object contains the following parameters:
   * @param {array} combinedDataArr array containing the transformed data.
   * Each element of the array is an object containing the data for each row.
   * Keys of the object are the [combinedSensorName] and the timestamp.
   * The value of the [combinedSensorName] key is an object with available metrics.
   * @param {array} combinedDataParams array is used to create the table columns. 
   * It contains metric keys from the [combinedSensorName] property of the combinedDataArr array.
   * @param {string} combinedSensorName A string containing the combined sensor name.
  */
  const { combinedDataArr, combinedDataParams, combinedSensorName } = transformDataToTable(data, measures, markerConfig);

  console.log('measures', Object.values(measures).flat(), markerConfig)
  /** Function to download the data as CSV. */
  const downloadCSV = () => {
    /** Transform the data to a format that can be stored in a CSV file. */
    let _data = []
    data.forEach((item) => {
      const keys = Object.keys(item)
      let _item = {}
      keys.forEach((key) => {
        if (key !== 'timestamp') {
          _item = {..._item, ...item[key]}
        } else {
          _item.timestamp = item.timestamp
        }
      })
      _data.push(_item)
    })
    /** Convert our data to CSV */ 
    const csv = Papa.unparse(_data, {columns:  ['timestamp', ...Object.values(measures).flat()]});
    /** Create a new Blob object using our CSV data */ 
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    /** Create a link element */ 
    let link = document.createElement("a");
    /** Create a URL to our blob */ 
    const url = URL.createObjectURL(blob);
    link.href = url;
    /** Set the download attribute on our link element */ 
    link.download = 'data.csv';
    /** Add our link element to the body */ 
    document.body.appendChild(link);
    /** Click the link element */ 
    link.click();
    /** Clean-up by removing link from body */ 
    document.body.removeChild(link);
  };

  if (isLoading) return <Typography>Loading...</Typography>;

  if (isError) return <Typography>Error fetching data.</Typography>;

  if (data.length === 0) return <Typography>Brak danych</Typography>;

  return (
    <div style={{position: "relative"}}>
      {/* Render the button to download the data as CSV. */}
      <Button style={{marginTop: "8px"}} variant="contained" size="small" onClick={downloadCSV}>Download CSV</Button>
      {/* Render a DataTable component to display the data. */}
      <DataTable 
        data={combinedDataArr} 
        params={combinedDataParams} 
        units={units}
        symbols={symbols}
      />
    </div>

  );
};