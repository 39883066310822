import arrowConfigWaves from "../data/East_North_Az.txt";
import arrowConfigSurface from "../data/vpd.csv";
import arrowConfigBottom from "../data/dpd.csv";
import waves_height_uri from "../data/East_North_Hs.txt";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { fromLonLat, toLonLat } from "ol/proj";
import { Fill, Icon, Text, RegularShape, Stroke, Style } from "ol/style";
import proj4 from "proj4";
import GeoJSON from "ol/format/GeoJSON";
import { circle, featureCollection, point, union } from "@turf/turf";
// eslint-disable-next-line import/no-webpack-loader-syntax
import arrow_gradient from "!file-loader!../assets/arrow_gradnient.svg";
import areaConfigLevel from "../data/bands_level.geojson";
import areaConfigPeriod from "../data/bands_period.geojson";
import areaConfigCurrentsSurface from "../data/bands_currents_surface.geojson";
import areaConfigCurrentsBottom from "../data/bands_currents_bottom.geojson";
import areaConfigHeight from "../data/bands_height.geojson";
import { configuration } from "../config";

const EPSG2180 =
  "+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=GRS80 +units=m +no_defs";
proj4.defs("EPSG:2180", EPSG2180);
const transform = proj4("EPSG:2180", "EPSG:3857");

export const layerProps = (layerToShow) => {
  switch (layerToShow) {
    case "period":
      return {
        az: "wd",
        magnitude: "wt",
      };
    case "currents_surface":
      return {
        az: "cd",
        magnitude: "cv",
      };
    case "currents_bottom":
      return {
        az: "cbd",
        magnitude: "cbv",
      };
    default:
    case "height":
      return {
        az: "wd",
        magnitude: "wh",
      };
  }
};

const layerToUri = (layerToShow) => {
  switch (layerToShow) {
    case "period":
      return arrowConfigWaves;
    case "currents_surface":
      return arrowConfigSurface;
    case "currents_bottom":
      return arrowConfigBottom;
    default:
    case "height":
      return arrowConfigWaves;
  }
};

export const fetchWaves = (forecast) => {
  return fetch(`${configuration.serverUri}/baltic_points/${forecast}`).then(async (res) => {
    const data = await res.json()
    const points = []
    data.forEach((point) => {
      const lat = point.y 
      const lon = point.x 
      if (!lat || !lon || lat > 7500000 || lon < 1500000) {
        return;
      }
      points.push(point)
    })
    return {points, forecast}
  })
}

export const prepareWaves = ({layerToShow, points}) => {
  const magnitudeProp = layerProps(layerToShow).magnitude
  const maxFeaturesMagnitudeProp = Math.max(...points.map((point) => parseFloat(point[magnitudeProp])))
  const features = []
  points.forEach((point) => {
    const lat = point.y 
    const lon = point.x 
    const az = point[`${layerProps(layerToShow).az}`]
    const magnitude = point[`${magnitudeProp}`]
    // console.log(layerToShow, az, magnitude)
    // console.log(toLonLat([parseFloat(lon), parseFloat(lat)]))
    const feature = new Feature({
      geometry: new Point(
        // transform.forward([parseFloat(lon), parseFloat(lat)])
        [parseFloat(lon), parseFloat(lat)]
      ),
      arrow: {
        az: parseFloat(az),
        magnitude: parseFloat(magnitude),
      },
    });
    let rotation = (az * Math.PI) / 180;
    let rotationDegrees = Math.abs((Number(rotation) * 180) / Math.PI);
    let iconStyle = new Style({
      overflow: true,
      image: new Icon({
        anchorXUnits: "fraction",
        anchorYUnits: "pixels",
        // scale: 0.75,
        scale: parseFloat(magnitude) / maxFeaturesMagnitudeProp,
        src: arrow_gradient,
        rotation,
        anchorOrigin: "top-left",
        // rotation:  0
      }),
    });
    feature.setStyle(iconStyle);
    features.push(feature);
  })
  return features
}

const whichBand = (bands, value) => {
  for (let index = 1; index < bands.length; index++) {
    if (value > bands[index - 1] && value <= bands[index]) {
      return index - 1;
    }
  }
  return null;
};
export const prepareIsobands = () => {
  console.log(waves_height_uri);
  const format = new GeoJSON();
  const breaks = [0, 0.5, 1, 1.5, 2, 9999];

  const colors = ["#19e4ff", "#009dff", "#006fff", "#003cff"];
  return fetch(waves_height_uri).then((res) => {
    return res.text().then((waves) => {
      const features_bands = [];
      for (let i = 0; i < breaks.length - 1; i++) {
        features_bands.push([]);
      }

      const allLines = waves.split(/\r\n|\n/);
      // Reading line by line
      allLines.forEach((line) => {
        const [lon, lat, hs] = line.split(/\s+/);
        // console.log(lon, lat,az)
        if (!lat || !lon || !hs) {
          return;
        }
        // console.log(transform.forward([parseFloat(lon), parseFloat(lat)]))
        const index = whichBand(breaks, hs);

        if (index != null) {
          // console.log('index', index, features_bands)

          const feature = circle(
            point(transform.forward([parseFloat(lon), parseFloat(lat)])),
            3,
            { properties: { hs: parseFloat(hs) } }
          );
          features_bands[index].push(feature);
        }
        // console.log('isopoints', featureCollection(features_bands))
      });
      let combined_bands = [];
      console.log(
        "feature bands",
        features_bands.map((i) => featureCollection(i))
      );
      for (let band of features_bands) {
        console.log("band", featureCollection(band));

        combined_bands.push(
          band.reduce((item, currentValue) => union(currentValue, item))
        );
        break;
      }
      for (let i = 0; i < combined_bands.length; i++) {
        combined_bands.forEach((item, index) => {
          item.style = new Style({
            color: colors[index],
          });
        });
      }
      console.log("combinedBands", combined_bands);
      // let grid = interpolate(featureCollection(features), 10, {property: 'hs', gridType:"point", weight:4})
      // console.log('grid', grid)
      // let tin_poly = tin(featureCollection(features), 'hs')
      // console.log('tin', tin_poly)
      // let tin
      // let isolines = isobands(precalculated, breaks, { zProperty: 'hs' })
      // console.log('isolines', isolines)
      // return format.readFeatures(isolines)
      // return   vectorSource.addFeatures(geojsonToFeatures(isolines, {
      //     featureProjection: 'EPSG:3857'
      //   }));
    });
  });
};
