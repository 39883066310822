const windTypes = {
  S: 0,
  SW: 45,
  W: 90,
  NW: 135,
  N: 180,
  NE: 225,
  E: 270,
  SE: 315,
};

/**
 * Function to get the wind type from the wind angle.
 * @param {number} wind - Wind angle in degrees.
 * @returns {string} - Wind type.
 */
export const getWindType = (wind) => {
  if(wind < 22.5) return 'N';
  if(wind < 67.5) return 'NE';
  if(wind < 112.5) return 'E';
  if(wind < 157.5) return 'SE';
  if(wind < 202.5) return 'S';
  if(wind < 247.5) return 'SW';
  if(wind < 292.5) return 'W';
  if(wind < 337.5) return 'NW';
  return 'N';
}

/**
 * Function to get the wind angle from the wind type.
 * @param {string} wind - Wind type.
 * @returns {number} - Wind angle in degrees.
 */
export const getWindAngle = ({we, ws}) => {
  return 180 + Math.atan2(-we, ws) * 180 / Math.PI;
}

/**
 * Function to get the wind speed from the wind type.
 * @param {string} wind - Wind type.
 * @returns {number} - Wind speed in m/s.
 */
export const getWindSpeed = ({we, ws}) => {
  return Math.sqrt(Math.pow(we, 2) + Math.pow(ws, 2));
}

/**
 * Function to get the wind view from the wind angle.
 * @param {number} angle - Wind angle in degrees.
 * @param {object} style - Style to apply to the wind type.
 * @returns {object} - Wind view.
 */
export const getWindView = (angle, style={}) => {
  return (
    <>
      {angle.toFixed(2)}° <span style={{
        transform: `rotate(${-angle+90}deg)`,
        position: 'absolute'
      }}
        >&larr;
      </span> 
      {` `}  
      <span style={{paddingLeft: "20px", ...style}}>{getWindType(angle)}</span>
    </>
  )
}
