import { Tile as LayerTile } from 'ol/layer'
import { XYZ } from 'ol/source'
import { RLayerTile } from 'rlayers'

const ACCESS_TOKEN = 'F6urg0uDduDA3enP88x9sLHTs90tZYvBICh6eCon7E8sYycN5K6V9inpkS27vMPM'

/**
 * An OpenStreetMap layer
 *
 * Requires an `RMap` context
 */
export default class RJAWGLight extends RLayerTile {
  source = XYZ

  constructor (props, context) {
    super(props, context)
    this.source = new XYZ({
      attributions: `v${process.env.REACT_APP_VERSION} <a href="http://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors`,
      minZoom: 0,
      maxZoom: 22,
      subdomains: 'abcd',
      url: 'https://tile.jawg.io/jawg-light/{z}/{x}/{y}.png?access-token=' + ACCESS_TOKEN

    })
    this.ol = new LayerTile({ source: this.source })
    this.eventSources = [this.ol, this.source]
  }

  refresh (prevProps) {
    super.refresh(prevProps)
    this.ol.setProperties({ label: 'JAWG Maps' })
  }
}
