import { markerTypes } from '../../utils/buoyConnect'
import { measureBtnNames, menuBtnNames } from '../map/olpMapConfig'

const CHANGE_FIELD = 'CHANGE_FIELD'
const TOGGLE_SET_FIELD = 'TOGGLE_SET_FIELD'

/** layerSets is an array of menu button names sets. 
 * Each set contains names of buttons that cannot be active at the same time.
*/
const layerSets = [
  new Set([
    menuBtnNames.SHOW_SURFACE_CURRENTS,
    menuBtnNames.SHOW_BOTTOM_CURRENTS,
    menuBtnNames.SHOW_COASTAL_FLOOD,
    menuBtnNames.SHOW_LEVEL,
    menuBtnNames.SHOW_WLADYSLAWOWO_WAVES,
    menuBtnNames.SHOW_JASTARNIA_WAVES,
  ]),
  new Set([
    menuBtnNames.SHOW_WAVE_HEIGHT,
    menuBtnNames.SHOW_WAVE_PERIOD,
  ]),
  new Set([
    menuBtnNames.SHOW_COAST_CURRENTS,
    menuBtnNames.SHOW_COAST_SEDIMENT,
    menuBtnNames.SHOW_COASTAL_EROSION,
  ]),
]

/**
 * filterValues is an object that contains a list of marker types for each measure button.
 * it is used to filter the markers on the map.
 */
export const filterValues = {
  [measureBtnNames.WAVES]: [markerTypes.WAVE_BUOY.id, markerTypes.ADCP.id],
  [measureBtnNames.CURRENTS]: [markerTypes.ADCP.id],
  [measureBtnNames.EDGE]: [markerTypes.EDGE_SENSOR.id],
  [measureBtnNames.METEO]: [markerTypes.METEO_STATION.id],
  [measureBtnNames.CAMERA]: [markerTypes.CAMERA.id],
}

export const initialMenuData = {
  [menuBtnNames.SHOW_TILES]: true,
  [menuBtnNames.SHOW_BALTIC]: false,
  [menuBtnNames.SHOW_MARKER]: true,
  [menuBtnNames.WAVES_VARIANT]: null,
  [menuBtnNames.SHOW_WAVES]: false,
  [menuBtnNames.SHOW_WAVE_HEIGHT]: true,
  [menuBtnNames.SHOW_COASTAL_FLOOD]: false,
  [menuBtnNames.SHOW_PROFILES]: false,
  [menuBtnNames.SHOW_PORT]: false,
  [menuBtnNames.SHOW_LEVEL]: false,
  [menuBtnNames.SHOW_CURRENTS]: false,
  [menuBtnNames.SHOW_SEDIMENT]: false,
  [menuBtnNames.SHOW_COASTAL_EROSION]: false,
  [menuBtnNames.SHOW_PREDICTIONS]: true,
  [menuBtnNames.SHOW_WLADYSLAWOWO_WAVES]: false,
  [menuBtnNames.SHOW_JASTARNIA_WAVES]: false,
  [menuBtnNames.MARKER_HIGHLIGHT]: new Set(Object.keys(filterValues)),
}

/** mapConfigurationReducer is a reducer function that handles the state of the menu buttons. */
export const mapConfigurationReducer = (state, action) => {
  console.log({action})
  const newState = { ...state }
  switch (action.action) {
    case CHANGE_FIELD:
      /** disable all buttons in the same set as the button that is being enabled. */
      if (action.value) {
        for (let layer of layerSets) {
          if (layer.has(action.name)) {
            for (let item of layer) {
              newState[item] = false
            }
          }
        }
      }
      /** enable or disable the button */
      newState[action.name] = action.value
      return newState

    case TOGGLE_SET_FIELD:
      newState[action.name] = new Set(state[action.name].values())
      if (state[action.name].has(action.value)) {
        newState[action.name].delete(action.value)
      } else {
        newState[action.name].add(action.value)
      }
      return newState
    default:
      return state
  }
}

export const changeField = (name, value) => ({ action: CHANGE_FIELD, name, value })
export const toggleSetField = (name, value) => ({ action: TOGGLE_SET_FIELD, name, value })
