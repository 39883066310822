import { RLayerVector } from 'rlayers'
import GeoJSON from 'ol/format/GeoJSON'
import profiles from '../../../data/profiles.geojson'
import { RFill, RStroke, RStyle } from 'rlayers/style'
import { useEffect, useState } from 'react'
import { loadGeojson } from '../../utils/loadGeoJson'

/**
 * ProfileLines Component
 * @constructor
 * This component renders a layer on the map which displays profile lines.
 * @param {number} zIndex - zIndex of the layer.
 */
export const ProfileLines = ({ zIndex = 300 }) => {
  const [features, setFeatures] = useState()

  /** Effect hook to fetch profile lines data */
  useEffect(() => {
    loadGeojson(profiles).then(r => {
      setFeatures(r)
    })
  }, [])

  return (
    features && (
      <RLayerVector features={features} format={GeoJSON} zIndex={zIndex}>
        <RStyle>
          <RStroke color={"#f30303"} width={1} />
          {/* <RFill color={"rgba(86,202,227,0.5)"} /> */}
        </RStyle>
      </RLayerVector>
    )
  );
}
