import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts'
import { format, fromUnixTime } from 'date-fns'
import React from 'react';
import ADCPVChart from './adcpVChart';

/**
 * DataChart Component
 * @constructor
 * This component renders a graph for a metric.
 * @param {array} data array containing data for the graph.
 * Each element of the array is an object containing the data for each graph point.
 * Keys of the object are the [combinedSensorName] and the timestamp.
 * The value of the [combinedSensorName] key is an object with available metrics.
 * @param {string} selectedGraph unique metric key. It possible includes the sensor name.
 * @param {string} combinedMesureName combined sensor name.
 * @param {string} units metric units.
 * @param {string} symbol metric symbol.
 */
export const DataChart = React.memo(({ data, selectedGraph, combinedMesureName, units = '', symbol }) => {

  /** Function to format the date. */
  const formatDate = (timestamp) => {
    // return format(new Date(timestamp), 'yyyy-MM-dd HH:mm');
    return format( fromUnixTime(timestamp), 'yyyy-MM-dd HH:mm');
  };

  /** metric name. */
  const _selectedGraph = selectedGraph?.split('(')[0];

  /** label for the y axis. */
  const yAxisLabelValue = `${_selectedGraph}, ${units}`
  let _yAxisLabelValue

  if (React.isValidElement(symbol)) {
    /** if symbol is react component wrap it and pass on */
    _yAxisLabelValue = <Label content={({ value, viewBox }) => {
      viewBox.x = -5
      viewBox.y = 10
      return <foreignObject {...viewBox} >{symbol}, {units}</foreignObject>
    }} position={'insideTop'}/>
  } else {
    /** capitalize first letter of first word */
    _yAxisLabelValue = symbol
      ? `${symbol}, ${units}`
      : yAxisLabelValue.charAt(0).toUpperCase() + yAxisLabelValue.slice(1)
    _yAxisLabelValue = { value: _yAxisLabelValue, position: 'insideTop', dy: 10, dx: -5 }
  }

  /** If the metric is 'v' and the combined sensor name is 'adcp' display the ADCPVChart component. */
  if(selectedGraph === 'v' && combinedMesureName === 'adcp') 
    return <ADCPVChart data={data} />
  data = data.filter((item)=> item?.[combinedMesureName]?.[selectedGraph] !==undefined)
  console.log('graph in', combinedMesureName, selectedGraph, data)
  return (
    <div>
      <LineChart width={800} height={400} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="unixTimestamp"
          type={'number'}
          domain={['dataMin', 'dataMax']}
          tickFormatter={formatDate}
        />
        <YAxis 
          label={_yAxisLabelValue}
        />
        <Tooltip 
          labelFormatter={(value) => {
            return formatDate(value);
          }}
          formatter={(value, name, item, index, payload) => {
            return value.toFixed(2);
          }}  
        />
        <Line  type="monotone"
          dataKey={`${combinedMesureName}.${selectedGraph}`} 
          name={selectedGraph} stroke="#8884d8" dot={null}
        />       
      </LineChart>     
    </div>
  );
});
