import React, { useContext, useEffect } from 'react'
import { AppContext } from '../../../App';
import { RFeature, RLayerVector, ROverlay, RStyle } from 'rlayers';
import { Point } from 'ol/geom';
import { getWindType } from '../../../utils/wind';

/**
 * PointDataLayer Component
 * @constructor
 * This component renders a layer on the map which may display a point with associated wind data in an overlay.
 * It is dependent on the global application state for rendering the point and is intended to show details 
 * about specific geographical points when they are active in the state.
 */
const PointDataLayer = ({layerToShow}) => {

  const {pointData, setPointData, buoyState} = useContext(AppContext);

  /** Extract and process wind details from pointData */
  const wd = Number(pointData?.wd);
  const wh = Number(pointData?.wh)
  const wt = Number(pointData?.wt);
  const windType = getWindType(wd);

  useEffect(() => {
  /**
   * Effect hook to reset point data when buoyState changes
   * (i.e., when a buoy's popup is opened/closed), the point data is cleared.
   */
    if(buoyState) {
      setPointData(null);
    }
  }, [buoyState])

  const close = (e) => {
    e.preventDefault();
    setPointData(null);
  }

  /** Check for the validity of point data. If it doesn't exist or lacks x/y coordinates, render nothing. */ 
  if(!pointData || !pointData.x || !pointData.y) 
    return null

  return(
    <RLayerVector zIndex={600}>
      <RFeature
        geometry={new Point([pointData.x, pointData.y])}
      >
        {/* <RStyle.RStyle>
          <RStyle.RIcon src="/svg/location.svg" anchor={[0.5, 0.8]} />
        </RStyle.RStyle> */}
        <ROverlay className="point-overlay">
          <a href="#" className="ol-popup-closer" onClick={close}></a>
          <div>kierunek fali: {wd}° {windType}</div>
          <div>wysokość fali: {wh} m</div>
          <div>okres fali: {wt} s</div>
        </ROverlay>
      </RFeature>
    </RLayerVector>
  )
}

export default PointDataLayer