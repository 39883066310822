import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { TextField, Container, Box } from '@mui/material';
import { format } from 'date-fns'
import "./stats.css"
import { FullTable } from './full-table';
import { useParams } from 'react-router-dom';
import {
  findBuoy,
  getMarkerConfig,
  getMetricsWithSymbols,
  getMetricsWithUnits,
  getSensorsWithMetricNames
} from './utils'

const queryClient = new QueryClient();

/**
 * Stats Component
 * @constructor
 * This component renders a table with data for a marker.
 * It displays the metrics from the marker's sensors.
 * It also displays a table to filter the data.
 */
const Stats = () => {
  
  /**  Get the 'id' parameter from the URL using useParams. */
  const {id} = useParams()

  /** Find the buoy's configuration and its group based on the marker's ID. */
  const {buoy, buoyGroup} = findBuoy(id)

  /** Get the available sensors with metrics names for the buoy configuration. */
  const sensorsWithMetricNames = getSensorsWithMetricNames(buoy, 'table')

  /** Get the metrics with units for the buoy configuration*/
  const metricsWithUnits = getMetricsWithUnits(buoy)

  /** Get the metrics with symbols for the buoy configuration*/
  const metricsWithSymbols = getMetricsWithSymbols(buoy)
  /** Get the metrics with symbols for the buoy configuration*/
  const markerConfig = getMarkerConfig(buoy)

  const prevMonthDate = 
    format(new Date(
      new Date().setMonth(new Date().getMonth() - 1)), 'yyyy-MM-dd'
    );

  const prevDaysDate =
    format(new Date(
      new Date().setDate(new Date().getDate() - 1)), 'yyyy-MM-dd'
    );

  const [startDate, setStartDate] = useState(prevDaysDate);
  const [startTime, setStartTime] = useState(format(new Date(), 'HH:mm'));
  const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'))
  const [endTime, setEndTime] = useState(format(new Date(), 'HH:mm'))

  return (
    <QueryClientProvider client={queryClient}>

    <div className='graph-header'>
      <div className='graph-header-title'>{buoy?.name}</div>
      <img src="/images/sensor.jpg" alt="Logo" />
    </div>
        
      {/* Render the table to filter the data. */}
      <Container>
        <Box mt={4}>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Start Time"
            type="time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />          
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="End Time"
            type="time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </Container>

      {/* Render the table to display the data. */}
      <FullTable
        startDate={`${startDate}T${startTime}`}
        endDate={`${endDate}T${endTime}`}
        measures={sensorsWithMetricNames}
        helapi = {buoyGroup?.helapi}
        units={metricsWithUnits}
        symbols={metricsWithSymbols}
        markerConfig={markerConfig}
      />

    </QueryClientProvider>
  );
};

export default Stats;
