import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { format } from 'date-fns'
import TimelineIcon from '@mui/icons-material/Timeline';
import { DataChart } from './data-chart';
import { useRef, useState } from 'react';
import ADCPVTableCell from './adcpVTableCell';
import Tooltip from '@mui/material/Tooltip'

/**
 * DataTable Component
 * @constructor
 * This component renders a table with the data for a marker 
 * and can display a graph for a metric when the graph icon is clicked.
 * @param {array} data array containing the transformed data.
 * Each element of the array is an object containing the data for each row.
 * Keys of the object are the [combinedSensorName] and the timestamp.
 * The value of the [combinedSensorName] key is an object with available metrics.
 * @param {array} params array is used to create the table columns. 
 * It contains metric keys from the [combinedSensorName] property of the data array.
 * @param {object} units - metrics with units.
 * @param {object} symbols - metrics with symbols.
 */
export const DataTable = ({ data, params, units, symbols }) => {

  /** State to display the graph in a dialog. 
   * It is set when the graph icon is clicked. It is reset when the dialog is closed.
   * The value is a string containing the metric key.
   * It possible includes the sensor name. */
  const [selectedGraph, setSelectedGraph] = useState(null);
  /** Units for the displayed graph. */
  const [selectedUnits, setSelectedUnits] = useState('');
  /** Symbol for the displayed graph. */
  const [selectedSymbol, setSelectedSymbol] = useState('');

  /** Reference to the dialog graph element. */
  const dialogRef = useRef(null);

  if (!data || data.length === 0) return null;

  /** Combined sensor name. */
  const sensorName = Object.keys(data[0]).filter((key) => key !== 'timestamp')[0];

  /** Function to display the graph in a dialog when the graph icon is clicked. */
  const showGraph = (param) => {
    setSelectedGraph(param);
    /** Get the metric name from the param. */
    const metricName = param.split('(')[0];
    setSelectedUnits(units[metricName] || '');
    setSelectedSymbol(symbols[metricName].symbol || '');
    dialogRef.current.showModal();
  }

  /** Function to close the dialog when the dialog is clicked outside the graph. */
  const onDialogClicked = (e) => {
    if (e.target === dialogRef.current) {
      dialogRef.current.close();
      setSelectedGraph(null);
      setSelectedUnits('');
    }
  }

  return (
    <>
      {/* Dialog to display the graph. */}
      <dialog ref={dialogRef} onClick={onDialogClicked}>
        <DataChart data={data} selectedGraph={selectedGraph} combinedMesureName={sensorName} 
          units={selectedUnits} symbol={selectedSymbol}
        />
      </dialog>

      {/* Table to display the data. */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              {params.map((param, index) => {
                /** Get the metric name from the param. */
                const _param = param.split('(')[0];
                return (<TableCell align="right" key={index}>
                  <div className='graph-icon-wrapper'>
                    <div className='graph-icon' onClick={()=>showGraph(param)}><TimelineIcon/></div>
                  </div>
                  <Tooltip title={symbols[_param]?.tooltip || _param}>

                  <div className='graph-cell-title'>{symbols[_param]?.symbol || _param}</div>
                  </Tooltip>
                </TableCell>)
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {format(new Date(row.timestamp), 'yyyy-MM-dd HH:mm:ss')}
                  {/* {row.timestamp} */}
                </TableCell>
                {params.map((param, index) => {
                  /** If the metric is 'v' and the combined sensor name is 'adcp' display the ADCPVChart component. */
                  if(param === 'v' && sensorName === 'adcp'){
                    return <ADCPVTableCell data={row?.[sensorName]?.[param]} key={index}/>
                  }
                  /* if the row is marked as wrong and the row has null show placeholder */
                  if (row.wrongData && row?.[sensorName]?.[param] === null){
                    return <TableCell align={'right'} key={index}>
                      {/*N/A*/}
                    </TableCell>
                  }
                  return (
                    <TableCell align="right" key={index}>
                      {row?.[sensorName]?.[param]?.toFixed(2) || row?.[sensorName]?.[param]}
                    </TableCell>
                )})}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

