import { Avatar, SvgIcon } from '@mui/material'
import React from 'react'

/**
 * MenuItemBar Component
 * @constructor
 * This component renders a menu item bar.
 * @param {function} onClick - click event handler.
 * @param {object} item - menu item.
 * @param {boolean} isParent - flag to indicate if the menu item is a parent.
 * @param {boolean} isSelected - flag to indicate if the menu item is selected.
 */
const MenuItemBar = ({onClick = ()=>{}, item, isParent, isSelected}) => {

  return (
    <div 
      className={`menu-item-bar ${isParent? 'parent': ''} ${isSelected? 'selected': ''}`} 
      onClick={onClick}
    >
      <div style={{display: "flex", alignItems: "center"}}>
        <div className='label'>
          {item.label}
        </div>  
        <div className='icon'>
          {/* <SvgIcon component={item.icon}/> */}
          <Avatar
            sx={{
              backgroundColor: isSelected? '#3E398FB2': 'white',
              color: isSelected? '#ffffff': '#3E398FB2',
              margin: 'auto',
              fontSize: 10,
              height: 24, 
              width: 24,

            }}
          >
            <SvgIcon component={item.icon} inheritViewBox/>
          </Avatar>
        </div>        
      </div>
    </div>
  )
}

export default MenuItemBar