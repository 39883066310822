import { RLayerVector } from 'rlayers'
import GeoJSON from 'ol/format/GeoJSON'
import { useContext, useEffect, useRef, useState } from 'react'
import { Icon, Style } from "ol/style";
import profiles from '../../../data/profiles.geojson'
import { AppContext } from '../../../App'
import { useQuery } from 'react-query'
import { ErosionChart } from '../../graph/erosion-chart'
import { fetchErosionData } from '../../../utils/fetchErosionData'

/** Define a function that adds a custom style to vector features. */
export const addStyle = ({scale=1, idx}) =>
  (feature, resolution) => {
    // shaft.setScale([1, scale]);
    // shaft.setRotation(angle);
    /** Create a new style for vector features with an image (icon) */
    let imgStyle = new Style({
      overflow: true,
      image: new Icon({
        // anchor: [10, 30],
        // anchorXUnits: 'pixels',
        // anchorYUnits: 'pixels',
        src: '/images/graphic.png', 
        opacity: scale,
        scale: 0.8,
        displacement: [
          idx % 2 === 0 ? 0 : 10, 
          idx % 2 === 0 ? 15 : 30
        ],
        offsetOrigin: 'bottom-left',
      }),
    });

    return [imgStyle];
  };

/**
 * ErosionLayer Component
 * @constructor
 * This component renders a layer on the map which displays erosion data.
 * @param {number} zIndex - zIndex of the layer.
 * @param {string} magnitudeField - magnitudeField is either 'u' or 'q' to determine which data to display.
 */
export const ErosionLayer = ({ zIndex = 900, magnitudeField }) => {
  const [features, setFeatures] = useState()

  const {currentForecast, nextForecast} = useContext(AppContext)

  /** This state is used to store the ID of the profile that is clicked. 
   * When there is a currentProfileId, the erosion chart will be displayed. */
  const [ currentProfileId, setCurrentProfileId ] = useState(null)

  /** This state is used to store the ID of the profile that is hovered. */
  const [ hoveredFeatureId, setHoveredFeatureId ] = useState(null)

  /** Effect hook to fetch erosion data when currentForecast changes. */
  const {data} = useQuery({
    queryKey: ['coastal_erosion', currentForecast],
    queryFn: () => fetchErosionData(currentForecast),
    enabled: !!currentForecast,
    retry: false,
    onSuccess: (data) => {
      console.log({data})
    }
  });

  /** Effect hook to fetch erosion data when nextForecast changes. 
   * This is used to fetch data to cache it for the next forecast.
  */
  useQuery({
    queryKey: ['coastal_erosion', nextForecast],
    queryFn: () => fetchErosionData(nextForecast),
    enabled: !!nextForecast,
    retry: false,
  });

  /**  This hook is used to create features from the data fetched by the useQuery hooks above. */
  useEffect(() => {
    if(currentForecast && data){
      fetch(profiles).then(r=>r.json()).then(_profiles => {

        const features = []

        /**  Iterate over data keys (profile IDs) to create features. */
        Object.keys(data).forEach((current) =>{
          
          function getProfileGeometryById(id){
            const profile = _profiles.features.find((p) => p.properties.id === id);
            if (!profile) {
              console.log(`Profile with id ${id} not found`);
              return null;
            }
            return profile.geometry;
          }

          /** get the geometry of the current profile by ID. */
          const profileGeometry = getProfileGeometryById(current);

          if(!profileGeometry) 
            return console.log('profileGeometry not found')

          const profileCoordinates = profileGeometry?.coordinates;
          const profileCoordinatesEndPoint = profileCoordinates[1];

          /** create a feature for the current profile. */
          const feature = { 
            "type": "Feature", 
            "properties": {
              "id": current,
            },
            "geometry": { 
              "type": "Point", 
              "coordinates": [profileCoordinatesEndPoint[0], profileCoordinatesEndPoint[1]]
            } 
          }
          features.push(feature)
        })

        /** create a GeoJSON object from the features array. */
        const geojson = new GeoJSON().readFeatures({
          "type": "FeatureCollection",
          "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:EPSG::3857" } },
          features
        })
        setFeatures(geojson);

      })
    }
  }, [currentForecast, magnitudeField, data])

  const layerRef = useRef(null);

  /** This hook is used to update the features of the layer when features state changes. */
  useEffect(() => {
    if (features && layerRef.current) {
      layerRef.current.source.clear();
      layerRef.current.source.addFeatures(features);
    }
  }, [features]);

  /** This function is used to open the erosion chart when a feature is clicked. */
  const onClick = (e) =>{
    e.preventDefault()
    e.stopPropagation()
    dialogRef.current.showModal();
    setCurrentProfileId(e.target.values_.id)    
  }

  /** This function is used to change the cursor when the mouse is over a feature. */
  const onPointerEnter = (e, idx) =>{
    e.map.getTargetElement().style.cursor = 'pointer';
    setHoveredFeatureId(e.target.values_.id)
  }

  /** This function is used to change the cursor when the mouse leaves a feature. */
  const onPointerLeave = (e, idx) =>{
    e.map.getTargetElement().style.cursor = 'auto';
    if(hoveredFeatureId === e.target.values_.id){
      setHoveredFeatureId(null)
    }
  }

  const dialogRef = useRef(null)

  /** Function to close the dialog when clicked outside its content. */
  const onDialogClicked = (e) => {
    if (e.target === dialogRef.current) {
      dialogRef.current.close();
    }
  }

  if(!features) return null

  return (
    <>
      {/* Dialog to display the erosion chart. */}
      <dialog ref={dialogRef} onClick={onDialogClicked}>
        <ErosionChart data={data?.[currentProfileId]}/>
      </dialog>
      {/* Render the layer. */}
      {features.map((feature, idx) => {
        const scale = hoveredFeatureId === feature.values_.id ? 1 : 0.7
        return (
          <RLayerVector
            key={`${idx}-${scale}`}
            features={[feature]}
            format={GeoJSON}
            zIndex={zIndex}
            style={addStyle({scale, idx})}
            onClick={onClick}
            onPointerLeave={e => onPointerLeave(e, idx)}
            onPointerMove={e => onPointerEnter(e, idx)}
          >
          </RLayerVector>
        )
      })}
    </>
  )
}
